<template>
  <div>
    <div v-if="profile_companies">
      <CompanyFollowUp
        :title="title"
        v-for="(item, index) in profile_companies"
        :key="index"
        :companyFollowUp="item ? item : 'Loading'"
      />
    </div>
    <div class="d-flex justify-content-center align-items-center mt-64" v-else>
      <div
        class="orbit-spinner d-flex justify-content-center align-items-center"
      >
        <div class="orbit"></div>
        <div class="orbit"></div>
        <div class="orbit"></div>
      </div>
    </div>
  </div>
</template>
<script>
import instance from "../../store/axiosConfig.js";
import CompanyFollowUp from "../components/ProfileFollowUp.vue";
export default {
  name: "PackageProfile",
  data() {
    return {
      title: "Profile Package",
      profile_companies: null,
    };
  },
  components: {
    CompanyFollowUp,
  },
  methods: {
    getDetails(id) {
      instance
        .get("/followup/" + id)
        .then((res) => {
          console.log(res.data);
          this.profile_companies = res.data.data;
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
  },
  created() {
    this.getDetails(2);
  },
};
</script>
