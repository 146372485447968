<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <card
          :title="stats.all.title"
          :value="stats.all.value"
          :percentage="stats.all.percentage"
          :iconClass="stats.all.iconClass"
          :iconBackground="stats.iconBackground"
          directionReverse
        ></card>
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <card
          :title="stats.full.title"
          :value="stats.full.value"
          :percentage="stats.full.percentage"
          :iconClass="stats.full.iconClass"
          :iconBackground="stats.iconBackground"
          directionReverse
        ></card>
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <card
          :title="stats.account.title"
          :value="stats.account.value"
          :percentage="stats.account.percentage"
          :iconClass="stats.account.iconClass"
          :iconBackground="stats.iconBackground"
          :percentageColor="stats.account.percentageColor"
          directionReverse
        ></card>
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0">
        <card
          :title="stats.directory.title"
          :value="stats.directory.value"
          :percentage="stats.directory.percentage"
          :iconClass="stats.directory.iconClass"
          :iconBackground="stats.iconBackground"
          directionReverse
        ></card>
      </div>
    </div>
    <div class="mt-4 row">
      <div class="mb-4 col-lg-5 mb-lg-0">
        <div class="card z-index-2">
          <div class="p-3 card-body">
            <!-- chart -->
            <active-users-chart />
          </div>
        </div>
      </div>
      <div class="col-lg-7">
        <!-- line chart -->
        <div class="card z-index-2">
          <gradient-line-chart />
        </div>
      </div>
    </div>
    <div class="row my-4">
      <div class="c0l-12 mb-md-0 mb-4">
        <company-card />
      </div>
    </div>
  </div>
  <input type="file" name="file" accept="image/*" capture> 
  <input type="file" name="file" accept="image/*" capture="camera">
</template>
<script>
import Card from "@/examples/Cards/Card.vue";
import ActiveUsersChart from "@/examples/Charts/ActiveUsersChart.vue";
import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
import CompanyCard from "./components/CompanyCard.vue";
import US from "../assets/img/icons/flags/US.png";
import DE from "../assets/img/icons/flags/DE.png";
import GB from "../assets/img/icons/flags/GB.png";
import BR from "../assets/img/icons/flags/BR.png";

export default {
  name: "dashboard-default",
  data() {
    return {
      stats: {
        iconBackground: "bg-gradient-success",
        all: {
          title: "All Companies",
          value: "53,000",
          percentage: "+55%",
          iconClass: "ni ni-money-coins",
        },
        full: {
          title: "Full Package",
          value: "2,300",
          percentage: "+3%",
          iconClass: "ni ni-world",
        },
        account: {
          title: "Account Package",
          value: "+3,462",
          percentage: "-2%",
          iconClass: "fas fa-user",
          percentageColor: "text-danger",
        },
        directory: {
          title: "Directory Package",
          value: "103,430",
          percentage: "+5%",
          iconClass: "fas fa-ad",
        },
      },
      sales: {
        us: {
          country: "United States",
          sales: 2500,
          value: "$230,900",
          bounce: "29.9%",
          flag: US,
        },
        germany: {
          country: "Germany",
          sales: "3.900",
          value: "$440,000",
          bounce: "40.22%",
          flag: DE,
        },
        britain: {
          country: "Great Britain",
          sales: "1.400",
          value: "$190,700",
          bounce: "23.44%",
          flag: GB,
        },
        brasil: {
          country: "Brasil",
          sales: "562",
          value: "$143,960",
          bounce: "32.14%",
          flag: BR,
        },
      },
    };
  },
  components: {
    Card,
    ActiveUsersChart,
    GradientLineChart,
    CompanyCard,
  },
};
</script>
