import instance from "../axiosConfig";
export default {
  state: {
    report_data: null,
  },

  mutations: {
    GET_DATA(state, data) {
      state.report_data = data;
    },
  },
  actions: {
    GETDATAREPORT({ commit }, { ...data }, type) {
      console.log(data);
      return new Promise((resolve, reject) => {
        instance
          .post("/report/" + type + "/", data)
          .then((response) => {
            console.log(response.data);
            commit("GET_DATA", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
