import axios from "axios";
import router from "../router";
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
});
// instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
instance.interceptors.request;
// import * as axios from "axios";
// axios.defaults.baseURL = "https://companies-guide-ca4jc73kxa-uc.a.run.app/api/";
instance.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const token = localStorage.getItem("token");

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const { response } = error;
    if (
      response &&
      response.status === 401 &&
      response.data.message == "Unauthenticated."
    ) {
      console.log(response);
      localStorage.removeItem("access_token");
      localStorage.removeItem("token");
      localStorage.removeItem("name");
      window.Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Your session has been expired",
      });
      router.replace({
        path: "/sign-in",
      });
    }
    return Promise.reject(error);
  }
);
export default instance;
