<template>
  <div class="card mb-4">
    <div class="card-header d-flex justify-content-between pb-0">
      <h6>Banner Table</h6>
      <div class="actions d-flex justify-content-center align-items-center">
        <!-- <vsud-select class="filterSales"></vsud-select> -->
        <vsud-badge
          v-if="userType == 'admin'"
          color="warning"
          role="button"
          variant="gradient"
          size="sm"
          >Report</vsud-badge
        >
        <router-link v-if="userType !== 'admin'" :to="{ name: 'AddCompany' }"
          ><vsud-badge
            style="margin-left: 15px"
            color="info"
            role="button"
            variant="gradient"
            size="sm"
            >Add</vsud-badge
          ></router-link
        >
      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0" v-if="company_table">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Index
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Company
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Banner
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Start Date
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                End Date
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Status
              </th>
              <th class="text-secondary opacity-7"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(company, index) in company_table.slice(0, perPage)"
              :key="company.company.id"
              role="button"
              class="row-hover"
            >
              <td style="width: 10px; text-align: center">
                <p class="text-xs font-weight-bold mb-0">
                  {{ index + 1 }}
                </p>
              </td>
              <td
                @click="
                  getDetails(
                    'company',
                    company.company.business_name,
                    company.company.id
                  )
                "
              >
                <div class="d-flex px-2 py-1">
                  <div>
                    <vsud-avatar
                      :img="
                        company.company.logo
                          ? company.company.logo.thumbnail_image
                          : ''
                      "
                      size="sm"
                      border-radius="lg"
                      class="me-3"
                      alt="user1"
                    />
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">
                      {{ company.company.business_name }}
                    </h6>
                    <p class="text-xs text-secondary mb-0">
                      {{ company.company.email }}
                    </p>
                  </div>
                </div>
              </td>
              <td class="text-center text-uppercase">
                <div>
                  <vsud-avatar
                    :img="
                      company.image
                        ? company.image.thumbnail_image
                        : 'No Banner'
                    "
                    size="sm"
                    border-radius="lg"
                    class="me-3"
                    alt="user1"
                  />
                </div>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ company.start_date }}
                </p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ company.end_date }}
                </p>
              </td>
              <td class="text-center text-uppercase">
                <vsud-badge
                  :color="company.status == 'active' ? 'success' : 'danger'"
                  role="button"
                  variant="gradient"
                  size="sm"
                  style="font-size: 10px; line-height: 1.25"
                  ><span class="d-block"> {{ company.status }}</span>
                </vsud-badge>
              </td>
              <td class="align-middle" v-if="userType == 'admin'">
                <router-link
                  :to="{
                    name: 'editCompany',
                    query: { company_id: company.id },
                  }"
                  class="text-secondary font-weight-bold text-xs"
                  data-toggle="tooltip"
                  data-original-title="Edit user"
                  >Edit</router-link
                >
                <a
                  style="margin-left: 15px"
                  @click="delete_company(company.id)"
                  class="text-danger font-weight-bold text-xs"
                  data-toggle="tooltip"
                  data-original-title="Delete user"
                  >Delete</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="d-flex justify-content-center align-items-center my-5" v-else>
        <div
          class="orbit-spinner d-flex justify-content-center align-items-center"
        >
          <div class="orbit"></div>
          <div class="orbit"></div>
          <div class="orbit"></div>
        </div>
      </div>
      <div
        class="d-flex justify-content-center align-items-center my-5"
        v-if="report_data"
      >
        <h5 v-if="report_data.length == 0" class="text-dark">No Companies</h5>
      </div>
      <!-- <pagination
        :records="company_table ? company_table.length : ''"
      ></pagination> -->
    </div>
  </div>
</template>

<script>
// import pagination from "@/components/pagination.vue";
import VsudAvatar from "@/components/VsudAvatar.vue";
import VsudBadge from "@/components/VsudBadge.vue";
// import VsudSelect from "@/components/VsudSelect.vue";
import img1 from "../../assets/img/team-2.jpg";
import { mapState } from "vuex";
import instance from "../../store/axiosConfig.js";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "banner table",
  data() {
    return {
      company_table: null,
      perPage: 10,
      img1,
    };
  },
  components: {
    VsudAvatar,
    VsudBadge,
    // pagination,
    // VsudSelect,
  },
  computed: {
    ...mapState({
      userType: (state) => state.auth.user_type,
    }),
  },
  methods: {
    CompanyTable() {
      instance
        .get("/banner/")
        .then((res) => {
          this.company_table = res.data.data;
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    delete_company(id) {
      this.$swal({
        title: "Do you want to Delete this?",
        showCancelButton: true,
        confirmButtonText: "Delete",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          instance
            .delete("/company/" + id)
            .then((res) => {
              this.CompanyTable();
              this.$router.push("/companies");
              toast.warning("Deleted!");
              console.log(res.data);
            })
            .catch((err) => {
              console.log(err.message);
            });
        }
      });
    },
    getDetails(type, name, id) {
      this.$router.push({
        path: "/reports/" + type + "/" + name + "/" + id,
        params: { sale_id: id },
      });
    },
  },
  created() {
    this.CompanyTable();
  },
};
</script>
<style>
.filterSales {
  margin-bottom: 0 !important;
  margin-right: 10px;
}
.filterSales .form-control {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
.row-hover:hover {
  background: #fafafa;
}
</style>
