<template>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class="mt-8 card card-plain">
                <div
                  class="pb-0 card-header text-start"
                  style="background-color: #f8f8f8"
                >
                  <h3 class="font-weight-bolder text-info text-gradient">
                    Add Media
                  </h3>
                </div>
                <div class="card-body">
                  <label>Title</label>
                  <input
                    v-model="name"
                    :class="'name' in errors ? 'is-invalid' : ''"
                    class="form-control"
                    type="text"
                    placeholder="Name"
                    name="name"
                  />
                  <p class="mt-2 text-sm invalid-feedback">
                    {{ errors.name ? errors.name[0] : "" }}
                  </p>
                  <label>Describtion</label>
                  <input
                    :class="'email' in errors ? 'is-invalid' : ''"
                    v-model="email"
                    class="form-control"
                    type="email"
                    placeholder="Email"
                    name="email"
                  />
                  <p class="mt-2 text-sm invalid-feedback">
                    {{ errors.email ? errors.email[0] : "" }}
                  </p>
                  <label>Image</label>
                  <input
                    :class="'dob' in errors ? 'is-invalid' : ''"
                    class="form-control"
                    type="file"
                    placeholder="Upload"
                    name="date"
                  />
                  <p class="mt-2 text-sm invalid-feedback">
                    {{ errors.dob ? errors.dob[0] : "" }}
                  </p>
                  <div class="text-center">
                    <vsud-button
                      @click="add()"
                      class="my-4 mb-2"
                      variant="gradient"
                      color="info"
                      fullWidth
                      >ِAdd
                    </vsud-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div
                class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8"
              >
                <div
                  class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                  :style="{
                    backgroundImage:
                      'url(' +
                      require('@/assets/img/curved-images/curved9.jpg') +
                      ')',
                  }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import VsudButton from "@/components/VsudButton.vue";
import instance from "../../store/axiosConfig.js";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "add-media",
  data() {
    return {
      errors: {},
      id: "",
      options: {
        countries: [],
        cities: [],
        areas: [],
      },
      country_status: "Country",
      city_status: "City",
      area_status: "Area",
      council_name: "",
      email: "",
      phone_number: "",
      landline_number: "",
      country: "Country",
      city: "City",
      area: "Area",
      person_in_charge_name: "",
      person_in_charge_designation: "",
      person_in_charge_email: "",
      person_in_charge_mobile: "",
      person_in_charge_country: null,
    };
  },
  components: {
    VsudButton,
  },
  methods: {
    RegisterCouncil() {
      instance
        .post("/council", {
          council_name: this.council_name,
          email: this.email,
          mobile_number: this.phone_number,
          landline_number: this.landline_number,
          country_id: this.country,
          city_id: this.city,
          area_id: this.area,
          person_in_charge_name: this.person_in_charge_name,
          person_in_charge_designation: this.person_in_charge_designation,
          person_in_charge_email: this.person_in_charge_email,
          person_in_charge_mobile: this.person_in_charge_mobile,
          person_in_charge_country: this.person_in_charge_country,
        })
        .then((res) => {
          console.log(res.data);
          this.$router.push({
            name: "Councils",
          });
          toast.success("Added Successfully!");
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
        });
    },
    get_country() {
      try {
        this.$store.dispatch("GETCOUNTRY").then((res) => {
          res.data.countries.forEach((item) => {
            this.options.countries.push(item);
          });
        });
      } catch (err) {
        console.log(err.message);
      }
    },
    get_city() {
      instance
        .get("/data/cities/" + this.country)
        .then((res) => {
          this.options.cities = [];
          console.log(res.data);
          if (res.data.cities.length > 0) {
            res.data.cities.forEach((city) => {
              this.options.cities.push(city);
            });
            this.city = "Select City";
          } else {
            this.city = "Not Found";
          }
        })
        .catch((error) => {
          console.log(error.data);
        });
    },
    get_area() {
      instance
        .get("/data/areas/" + this.city)
        .then((res) => {
          this.options.areas = [];
          console.log(res.data);
          if (res.data.areas.length > 0) {
            res.data.areas.forEach((area) => {
              this.options.areas.push(area);
            });
            this.area = "Select Area";
          } else {
            this.area = "Not Found";
          }
        })
        .catch((error) => {
          console.log(error.data);
        });
    },
  },
  created() {
    this.get_country();
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
  },
};
</script>
<style>
.dropdown {
  font-size: 0.875rem;
}
.select::-ms-expand {
  display: none;
  padding: 10px;
}
.dropdown .vs__dropdown-menu {
  height: auto;
  overflow-y: auto;
  float: left;
  position: absolute;
  transform: scale(1, 1);
  transition: transform ease 150ms;
  transform-origin: top;
  background-color: #ffffff;
  border-radius: 6px;
  border: solid 1px #eef0f1;
  box-shadow: 0 20px 40px 0 rgb(0 0 0 / 5%);
  /* padding: 15px 0; */
  /* z-index: 20; */
  margin-top: 10px;
  max-height: 360px;
  width: 100%;
  /* max-width: 332px; */
}
.dropdown .vs__dropdown-menu > * {
  transform: scale(1, 1);
  transition: transform ease 150ms;
  transform-origin: top;
  padding: 15.5px 24px;
  border-bottom: 1px solid #eef0f1;
  font-size: 0.875rem;
  line-height: 24px;
  box-sizing: border-box;
  color: #2b2d2e;
  display: block;
  text-decoration: none;
  text-align: left;
  background: none;
}
.dropdown .vs__dropdown-menu > *:hover {
  font-weight: 600;
  color: #213a63;
}
.dropdown .vs__dropdown-toggle {
  border-radius: 10px;
  border: solid 1px #b6b8b9;
  padding: 5px 10px;
  box-sizing: border-box;
}
.form-control {
  border: solid 1px #888 !important;
}
.form-control:focus {
  border: solid 1px #888 !important;
  box-shadow: none !important;
}
</style>
