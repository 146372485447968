import instance from "../axiosConfig";
export default {
  state: {
    accesstoken: JSON.parse(localStorage.getItem("access_token")) || null,
    loggedIn: false,
    sales_data: "",
    sales_table: "",
    packages: "",
    name: localStorage.getItem("name") || null,
  },

  getters: {
    auth(state) {
      return state.loggedIn;
    },
  },

  mutations: {
    ADD_SALES(state, data) {
      state.sales_data = data;
    },

    GET_PACKAGES(state, data) {
      state.packages = data;
    },
  },
  actions: {
    ADDSALES({ commit }, { ...data }) {
      console.log(data);
      return new Promise((resolve, reject) => {
        instance
          .post("/sale", data)
          .then((response) => {
            console.log(response.data);
            commit("ADD_SALES", data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    GETSALES({ commit }) {
      return new Promise((resolve, reject) => {
        instance
          .get("/sale")
          .then((response) => {
            console.log(response.data);
            commit("GET_SALES", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    GETPACKAGES({ commit }) {
      return new Promise((resolve, reject) => {
        instance
          .get("/data/packages")
          .then((response) => {
            console.log(response.data);
            commit("GET_PACKAGES", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
