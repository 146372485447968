<template>
  <div>
    <div class="row">
      <div class="col-md-12 mb-md-0 mb-4">
        <label for="">Discount</label>
        <div
          class="card card-body p-0 m-2 border card-plain border-radius-lg d-flex align-items-center flex-row"
        >
          <input
            disabled
            type="text"
            name=""
            placeholder="Discount"
            class="pay-input border-radius-lg"
            :value="Discount"
          />
        </div>
      </div>
      <div class="col-md-12">
        <label for="">Discount Amount</label>
        <div
          class="card card-body p-0 m-2 border card-plain border-radius-lg d-flex align-items-center flex-row"
        >
          <input
            disabled
            type="text"
            name=""
            placeholder="Discount Amount"
            class="pay-input border-radius-lg"
            :value="Discount_amount"
          />
        </div>
      </div>
      <div class="col-md-12">
        <label for="">Amount</label>
        <div
          class="card card-body p-0 m-2 border card-plain border-radius-lg d-flex align-items-center flex-row"
        >
          <input
            disabled
            type="text"
            name=""
            placeholder="Amount"
            class="pay-input border-radius-lg"
            :value="Amount"
          />
        </div>
      </div>
      <div class="col-md-12">
        <label for="">Reference Number</label>
        <div
          class="card card-body p-0 m-2 border card-plain border-radius-lg d-flex align-items-center flex-row"
        >
          <input
            type="text"
            name=""
            placeholder="Reference Number"
            class="pay-input border-radius-lg"
            v-model="reference_number"
          />
        </div>
      </div>
      <div class="col-md-12">
        <label for="">Bank Name</label>
        <div
          class="card card-body p-0 m-2 border card-plain border-radius-lg d-flex align-items-center flex-row"
        >
          <input
            type="text"
            name=""
            placeholder="Bank Name"
            class="pay-input border-radius-lg"
            v-model="bank_name"
          />
        </div>
      </div>
      <div class="col-sm-12">
        <button type="button" class="form-control" @click="Pay()">Pay</button>
      </div>
    </div>
  </div>
</template>
<script>
import instance from "../../store/axiosConfig.js";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "cheque",
  props: {
    Discount: Number,
    Discount_amount: Number,
    Amount: Number,
  },
  data() {
    return {
      reference_number: null,
      bank_name: null,
    };
  },
  methods: {
    Pay() {
      instance
        .post("/company/pay/bank", {
          discount_percentage: this.Discount,
          discount_amount: this.Discount_amount,
          amount: this.Amount,
          bank_name: this.bank_name,
          reference_number: this.reference_number,
        })
        .then((res) => {
          console.log(res.data);
          toast.success("Added Successfully!");
          this.$router.push({
            name: "SaleHome",
          });
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
  },
};
</script>
<style></style>
