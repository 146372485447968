<template>
  <div>
    <div class="row">
      <div class="col-md-12 mb-md-0 mb-4">
        <label for="">Discount</label>
        <div
          class="card card-body p-0 m-2 border card-plain border-radius-lg d-flex align-items-center flex-row"
        >
          <input
            disabled
            type="text"
            name=""
            placeholder="Discount"
            class="pay-input border-radius-lg"
            :value="Discount"
          />
        </div>
      </div>
      <div class="col-md-12">
        <label for="">Discount Amount</label>
        <div
          class="card card-body p-0 m-2 border card-plain border-radius-lg d-flex align-items-center flex-row"
        >
          <input
            disabled
            type="text"
            name=""
            placeholder="Discount Amount"
            class="pay-input border-radius-lg"
            :value="Discount_amount"
          />
        </div>
      </div>
      <div class="col-md-12">
        <label for="">Amount</label>
        <div
          class="card card-body p-0 m-2 border card-plain border-radius-lg d-flex align-items-center flex-row"
        >
          <input
            disabled
            type="text"
            name=""
            placeholder="Amount"
            class="pay-input border-radius-lg"
            :value="Amount"
          />
        </div>
      </div>
      <div class="col-md-12">
        <label for="">Cheque Number</label>
        <div
          class="card card-body p-0 m-2 border card-plain border-radius-lg d-flex align-items-center flex-row"
        >
          <input
            type="text"
            name=""
            placeholder="Cheque Number"
            class="pay-input border-radius-lg"
            v-model="cheque_number"
          />
        </div>
      </div>
      <div class="col-md-12">
        <label for="">Bank Name</label>
        <div
          class="card card-body p-0 m-2 border card-plain border-radius-lg d-flex align-items-center flex-row"
        >
          <input
            type="text"
            name=""
            placeholder="Bank Name"
            class="pay-input border-radius-lg"
            v-model="bank_name"
          />
        </div>
      </div>
      <div class="col-md-12">
        <UploadImg
          @src="getimg($event)"
          class="m-2 mb-3 card-plain border-radius-lg"
        />
      </div>
      <div class="col-sm-12">
        <button type="button" class="form-control" @click="Pay()">Pay</button>
      </div>
    </div>
  </div>
</template>
<script>
import instance from "../../store/axiosConfig.js";
import UploadImg from "../upload/UploadImg";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "cheque",
  props: {
    Discount: Number,
    Discount_amount: Number,
    Amount: Number,
  },
  data() {
    return {
      cheque_image: "",
      cheque_number: null,
      bank_name: null,
      currentImage: null,
    };
  },
  components: {
    UploadImg,
  },
  methods: {
    getimg(value) {
      this.cheque_image = value;
    },
    Pay() {
      let formData = new FormData();
      formData.append("discount_percentage", this.Discount);
      formData.append("discount_amount", this.Discount_amount);
      formData.append("amount", this.Amount);
      formData.append("cheque_number", this.cheque_number);
      formData.append("bank_name", this.bank_name);
      formData.append("cheque_image", this.cheque_image);
      instance
        .post("/company/pay/cheque", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res.data);
          toast.success("Added Successfully!");
          this.$router.push({
            name: "SaleHome",
          });
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
  },
};
</script>
<style>
.pay-input {
  width: 100%;
  border: 0;
  padding: 10px;
  outline: 0;
}
.uploader-box {
  display: flex;
  justify-content: center;
}
.choose-file-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 39px;
  padding: 0;
  padding-left: 9px;
  padding-right: 5px;
}
.choose-file-btn i {
  font-size: 20px;
  margin-left: 2px;
}
</style>
