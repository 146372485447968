<template>
  <div class="container">
    <div
      class="pt-5 m-3 page-header align-items-start min-vh-50 pb-11 border-radius-lg"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved6.jpg') + ')',
      }"
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="mx-auto text-center col-lg-5">
            <h4 class="mt-5 mb-2 text-white">Register Company!</h4>
            <p class="text-white text-lead">Kindly Fill All The Fields Below</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
      <div class="mx-auto col-sm-12 col-xl-8 col-lg-8 col-md-8">
        <div class="card z-index-0">
          <div class="p-4 text-center card-header">
            <h5>Company Information</h5>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="mb-3 col-md-6 col-sm-12">
                <input
                  :class="'business_name' in errors ? 'is-invalid' : ''"
                  class="form-control"
                  v-model="business_name"
                  id="name"
                  name="name"
                  type="text"
                  required=""
                  placeholder="Business Name"
                  aria-label="Name"
                />
                <p class="mt-2 text-sm text-danger">
                  {{ errors.business_name ? errors.business_name[0] : "" }}
                </p>
              </div>
              <div class="mb-3 col-md-6 col-sm-12">
                <input
                  :class="'email' in errors ? 'is-invalid' : ''"
                  class="form-control"
                  v-model="email"
                  id="email"
                  name="email"
                  type="text"
                  required=""
                  placeholder="Email"
                  aria-label="Email"
                />
                <p class="mt-2 text-sm text-danger">
                  {{ errors.email ? errors.email[0] : "" }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="mb-3 col-md-6 col-sm-12">
                <input
                  :class="'phone_number' in errors ? 'is-invalid' : ''"
                  class="form-control"
                  v-model="phone_number"
                  id="phone"
                  name="phone"
                  type="tel"
                  required=""
                  placeholder="Mobile"
                  aria-label="Phone"
                />
                <p class="mt-2 text-sm text-danger">
                  {{ errors.phone_number ? errors.phone_number[0] : "" }}
                </p>
              </div>
              <div class="mb-3 col-md-6 col-sm-12">
                <input
                  :class="'landline_number' in errors ? 'is-invalid' : ''"
                  class="form-control"
                  v-model="landline_number"
                  id="name"
                  name="name"
                  type="tel"
                  required=""
                  placeholder="Landline"
                  aria-label="Name"
                />
                <p class="mt-2 text-sm text-danger">
                  {{ errors.landline_number ? errors.landline_number[0] : "" }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="mb-3 col-md-6 col-sm-12">
                <input
                  :class="'website' in errors ? 'is-invalid' : ''"
                  class="form-control"
                  v-model="website"
                  id="name"
                  name="name"
                  type="text"
                  required=""
                  placeholder="Website"
                  aria-label="Name"
                />
                <p class="mt-2 text-sm text-danger">
                  {{ errors.website ? errors.website[0] : "" }}
                </p>
              </div>
              <div class="mb-3 col-md-6 col-sm-12">
                <input
                  :class="'trade_license_number' in errors ? 'is-invalid' : ''"
                  class="form-control"
                  v-model="trade_license_number"
                  id="name"
                  name="name"
                  type="text"
                  required=""
                  placeholder="License"
                  aria-label="Name"
                />
                <p class="mt-2 text-sm text-danger">
                  {{
                    errors.trade_license_number
                      ? errors.trade_license_number[0]
                      : ""
                  }}
                </p>
              </div>
            </div>
            <div class="mb-3">
              <v-select
                :class="'company_activity_id' in errors ? 'invalid' : ''"
                @search="get_activities($event)"
                v-model="selected_activities"
                class="dropdown"
                :options="options.activities"
                :reduce="(option) => option.id"
                label="title"
                placeholder="Activities"
                taggable
                push-tags
                multiple
              >
              </v-select>
              <p class="mt-2 text-sm text-danger">
                {{
                  errors.company_activity_id
                    ? errors.company_activity_id[0]
                    : ""
                }}
              </p>
            </div>
            <div class="row">
              <div class="mb-3 col-md-6 col-sm-12">
                <v-select
                  :class="'legal_status' in errors ? 'invalid' : ''"
                  class="dropdown"
                  v-model="legal_status"
                  :options="options.legal_status"
                  placeholder="Legal Status"
                  taggable
                  push-tags
                >
                </v-select>
                <p class="mt-2 text-sm text-danger">
                  {{ errors.legal_status ? errors.legal_status[0] : "" }}
                </p>
              </div>
              <div class="mb-3 col-md-6 col-sm-12">
                <v-select
                  :class="'issued_by' in errors ? 'invalid' : ''"
                  class="dropdown"
                  v-model="issued_by"
                  :options="options.issued_by"
                  placeholder="Issued By"
                  taggable
                  push-tags
                >
                </v-select>
                <p class="mt-2 text-sm text-danger">
                  {{ errors.issued_by ? errors.issued_by[0] : "" }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="mb-3 col-md-5 col-sm-12">
                <v-select
                  :class="'country_id' in errors ? 'invalid' : ''"
                  @option:selected="get_city"
                  class="dropdown"
                  v-model="country"
                  :options="options.countries"
                  :reduce="(country) => country.id"
                  :placeholder="country_status"
                  label="name"
                  taggable
                  push-tags
                >
                </v-select>
                <p class="mt-2 text-sm text-danger">
                  {{ errors.country_id ? errors.country_id[0] : "" }}
                </p>
              </div>
              <div class="mb-3 col-md-4 col-sm-12">
                <v-select
                  :class="'city_id' in errors ? 'invalid' : ''"
                  class="dropdown"
                  @option:selected="get_area"
                  v-model="city"
                  :options="options.cities"
                  label="name"
                  :reduce="(city) => city.id"
                  :placeholder="city_status"
                  taggable
                  push-tags
                >
                </v-select>
                <p class="mt-2 text-sm text-danger">
                  {{ errors.city_id ? errors.city_id[0] : "" }}
                </p>
              </div>
              <div class="mb-3 col-md-3 col-sm-12">
                <v-select
                  :class="'area_id' in errors ? 'invalid' : ''"
                  class="dropdown"
                  v-model="area"
                  :options="options.areas"
                  label="name"
                  :reduce="(area) => area.id"
                  :placeholder="area_status"
                  taggable
                  push-tags
                >
                </v-select>
                <p class="mt-2 text-sm text-danger">
                  {{ errors.area_id ? errors.area_id[0] : "" }}
                </p>
              </div>
            </div>
          </div>
          <div class="text-center">
            <h6>Person in Charge</h6>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="mb-3 col-md-6 col-sm-12">
                <input
                  :class="'person_in_charge_name' in errors ? 'is-invalid' : ''"
                  class="form-control"
                  v-model="person_in_charge_name"
                  id="name"
                  name="name"
                  type="text"
                  required=""
                  placeholder="Name"
                  aria-label="Name"
                />
                <p class="mt-2 text-sm text-danger">
                  {{
                    errors.person_in_charge_name
                      ? errors.person_in_charge_name[0]
                      : ""
                  }}
                </p>
              </div>
              <div class="mb-3 col-md-6 col-sm-12">
                <input
                  :class="
                    'person_in_charge_designation' in errors ? 'is-invalid' : ''
                  "
                  class="form-control"
                  v-model="person_in_charge_designation"
                  id="email"
                  name="designation"
                  type="text"
                  required=""
                  placeholder="Designation"
                  aria-label="Designation"
                />
                <p class="mt-2 text-sm text-danger">
                  {{
                    errors.person_in_charge_designation
                      ? errors.person_in_charge_designation[0]
                      : ""
                  }}
                </p>
              </div>
            </div>
            <div class="mb-3">
              <input
                :class="'person_in_charge_email' in errors ? 'is-invalid' : ''"
                class="form-control"
                v-model="person_in_charge_email"
                id="email"
                name="email"
                type="email"
                required=""
                placeholder="Email"
                aria-label="email"
              />
              <p class="mt-2 text-sm text-danger">
                {{
                  errors.person_in_charge_email
                    ? errors.person_in_charge_email[0]
                    : ""
                }}
              </p>
            </div>
            <div class="row">
              <div class="mb-3 col-md-6 col-sm-12">
                <input
                  :class="
                    'person_in_charge_mobile' in errors ? 'is-invalid' : ''
                  "
                  class="form-control"
                  v-model="person_in_charge_mobile"
                  id="name"
                  name="Mobile"
                  type="tel"
                  required=""
                  placeholder="Mobile"
                  aria-label="Name"
                />
                <p class="mt-2 text-sm text-danger">
                  {{
                    errors.person_in_charge_mobile
                      ? errors.person_in_charge_mobile[0]
                      : ""
                  }}
                </p>
              </div>
              <div class="mb-3 col-md-6 col-sm-12">
                <v-select
                  :class="'person_in_charge_country' in errors ? 'invalid' : ''"
                  class="dropdown"
                  v-model="person_in_charge_country"
                  :options="options.countries"
                  :reduce="(country) => country.id"
                  placeholder="Nationality"
                  label="name"
                  taggable
                  push-tags
                >
                </v-select>
                <p class="mt-2 text-sm text-danger">
                  {{
                    errors.person_in_charge_country
                      ? errors.person_in_charge_country[0]
                      : ""
                  }}
                </p>
              </div>
            </div>
            <div class="text-center">
              <vsud-button
                @click="AddCompany()"
                color="dark"
                fullWidth
                variant="gradient"
                class="my-4 mb-2"
                >Next</vsud-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VsudButton from "@/components/VsudButton.vue";
import vSelect from "vue-select";
import instance from "../../store/axiosConfig.js";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "add-company",
  data() {
    return {
      errors: {},
      id: "",
      options: {
        activities: [],
        countries: [],
        cities: [],
        areas: [],
        legal_status: [],
        issued_by: [],
      },
      country_status: "Country",
      city_status: "City",
      area_status: "Area",
      business_name: "",
      email: "",
      phone_number: "",
      landline_number: "",
      website: "",
      trade_license_number: "",
      selected_activities: null,
      legal_status: null,
      issued_by: null,
      country: "Country",
      city: "City",
      area: "Area",
      person_in_charge_name: "",
      person_in_charge_designation: "",
      person_in_charge_email: "",
      person_in_charge_mobile: "",
      person_in_charge_country: null,
    };
  },
  components: {
    VsudButton,
    vSelect,
  },
  computed: {
    filteroptions() {
      return this.options.activities.filter((item) => {
        return this.selected_activities
          .toLowerCase()
          .split(" ")
          .every((v) => item.toLowerCase().includes(v));
      });
    },
  },
  methods: {
    AddCompany() {
      this.$store
        .dispatch("ADDCOMPANY", {
          business_name: this.business_name,
          email: this.email,
          phone_number: this.phone_number,
          landline_number: this.landline_number,
          trade_license_number: this.trade_license_number,
          website: this.website,
          company_activity_id: JSON.stringify(this.selected_activities),
          legal_status: this.legal_status,
          issued_by: this.issued_by,
          country_id: this.country,
          city_id: this.city,
          area_id: this.area,
          person_in_charge_name: this.person_in_charge_name,
          person_in_charge_designation: this.person_in_charge_designation,
          person_in_charge_email: this.person_in_charge_email,
          person_in_charge_mobile: this.person_in_charge_mobile,
          person_in_charge_country: this.person_in_charge_country,
        })
        .then((res) => {
          console.log(res.data);
          toast.success("Added Successfully!");
          this.$router.push({
            name: "Packages",
            query: { company_id: res.data.id },
          });
        })
        .catch((err) => {
          console.log(err.message);
          this.errors = err.response.data.errors;
        });
    },
    get_activities(act) {
      instance
        .get("/data/company_activities/" + act)
        .then((res) => {
          console.log(res.data);
          this.options.activities = [];
          res.data.activities.forEach((item) => {
            this.options.activities.push(item);
          });
          // console.log(this.options.activities);
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    get_legal_status() {
      try {
        this.$store.dispatch("GETLEGALSTATUS").then((res) => {
          res.data.legal_statuses.forEach((item) => {
            this.options.legal_status.push(item.name);
          });
        });
      } catch (err) {
        console.log(err.message);
      }
    },
    get_issued_by() {
      try {
        this.$store.dispatch("GETISSUEDBY").then((res) => {
          res.data.issued_by.forEach((item) => {
            this.options.issued_by.push(item.name);
          });
        });
      } catch (err) {
        console.log(err.message);
      }
    },
    get_country() {
      try {
        this.$store.dispatch("GETCOUNTRY").then((res) => {
          res.data.countries.forEach((item) => {
            this.options.countries.push(item);
          });
        });
      } catch (err) {
        console.log(err.message);
      }
    },
    get_city() {
      instance
        .get("/data/cities/" + this.country)
        .then((res) => {
          this.options.cities = [];
          console.log(res.data);
          if (res.data.cities.length > 0) {
            res.data.cities.forEach((city) => {
              this.options.cities.push(city);
            });
            this.city = "Select City";
          } else {
            this.city = "Not Found";
          }
        })
        .catch((error) => {
          console.log(error.data);
        });
    },
    get_area() {
      instance
        .get("/data/areas/" + this.city)
        .then((res) => {
          this.options.areas = [];
          console.log(res.data);
          if (res.data.areas.length > 0) {
            res.data.areas.forEach((area) => {
              this.options.areas.push(area);
            });
            this.area = "Select Area";
          } else {
            this.area = "Not Found";
          }
        })
        .catch((error) => {
          console.log(error.data);
        });
    },
  },
  created() {
      instance
        .get("/company/order_info")
        .then((res) => {
          if(res.data.company_info!=null)
          {
            this.company_info = res.data.company_info;
          }
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err.message);
        });
    
    this.get_legal_status();
    this.get_issued_by();
    this.get_country();
    // this.get_city();
    // this.get_area();
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
  },
};
</script>
<style>
.dropdown {
  font-size: 0.875rem;
}
.select::-ms-expand {
  display: none;
  padding: 10px;
}
.dropdown .vs__dropdown-menu {
  height: auto;
  overflow-y: auto;
  float: left;
  position: absolute;
  transform: scale(1, 1);
  transition: transform ease 150ms;
  transform-origin: top;
  background-color: #ffffff;
  border-radius: 6px;
  border: solid 1px #eef0f1;
  box-shadow: 0 20px 40px 0 rgb(0 0 0 / 5%);
  /* padding: 15px 0; */
  /* z-index: 20; */
  margin-top: 10px;
  max-height: 360px;
  width: 100%;
  /* max-width: 332px; */
}
.dropdown .vs__dropdown-menu > * {
  transform: scale(1, 1);
  transition: transform ease 150ms;
  transform-origin: top;
  padding: 15.5px 24px;
  border-bottom: 1px solid #eef0f1;
  font-size: 0.875rem;
  line-height: 24px;
  box-sizing: border-box;
  color: #2b2d2e;
  display: block;
  text-decoration: none;
  text-align: left;
  background: none;
}
.dropdown .vs__dropdown-menu > *:hover {
  font-weight: 600;
  color: #213a63;
}
.dropdown .vs__dropdown-toggle {
  border-radius: 10px;
  border: solid 1px #b6b8b9;
  padding: 5px 10px;
  box-sizing: border-box;
}
/* .vs--open {
  border: solid 1px #e293d3;
  box-shadow: 0 0 0 2px #e9aede;
  border-radius: 10px;
} */
</style>
