<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow"
          btnBackground="bg-gradient-success"
          v-bind:darkMode="true"
        />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class="mt-8 card card-plain">
                <div class="pb-0 card-header text-start">
                  <h3 class="font-weight-bolder text-info text-gradient">
                    Add Sales Member
                  </h3>
                </div>
                <div class="card-body">
                  <label>Name</label>
                  <input
                    v-model="name"
                    class="form-control"
                    type="text"
                    placeholder="Name"
                    name="name"
                  />
                  <label>Email</label>
                  <input
                    v-model="email"
                    class="form-control"
                    type="email"
                    placeholder="Email"
                    name="email"
                  />
                  <label>Phone Number</label>
                  <input
                    v-model="phone_number"
                    class="form-control"
                    type="text"
                    placeholder="Phone Number"
                    name="text"
                  />
                  <label>Gender</label>
                  <select
                    v-model="gender"
                    class="form-control"
                    placeholder="Gender"
                    name="gender"
                  >
                    <option value="Gender">Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                  <label>Birth Date</label>
                  <input
                    v-model="birth_date"
                    class="form-control"
                    type="date"
                    placeholder="Birth Date"
                    name="date"
                  />
                  <div class="text-center">
                    <vsud-button
                      @click="setEdit(this.$route.params.id)"
                      class="my-4 mb-2"
                      variant="gradient"
                      color="info"
                      fullWidth
                      >Save
                    </vsud-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div
                class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8"
              >
                <div
                  class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                  :style="{
                    backgroundImage:
                      'url(' +
                      require('@/assets/img/curved-images/curved9.jpg') +
                      ')',
                  }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import instance from "../../store/axiosConfig.js";
import Navbar from "@/examples/PageLayout/Navbar.vue";
import VsudButton from "@/components/VsudButton.vue";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "add-sales-member",
  data() {
    return {
      name: "",
      email: "",
      phone_number: "",
      sales_area: "",
      gender: "",
      birth_date: "",
      sale: null,
    };
  },
  components: {
    Navbar,
    VsudButton,
  },
  methods: {
    getEdit(id) {
      instance
        .get("/sale/" + id)
        .then((res) => {
          this.name = res.data.name;
          this.email = res.data.email;
          this.phone_number = res.data.phone_number;
          this.gender = res.data.gender;
          this.birth_date = res.data.birth_date;
          console.log(res.data);
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
    setEdit(id) {
      instance
        .put("/sale/" + id, {
          name: this.name,
          email: this.email,
          phone_number: this.phone_number,
          gender: this.gender,
          dob: this.birth_date,
        })
        .then((res) => {
          console.log(res.data);
          toast.success("Edited Successfully");
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
  },
  mounted() {
    this.getEdit(this.$route.params.id);
  },
};
</script>
