<template>
  <div class="container center">
    <div class="row justify-content-center align-items-center">
      <div class="col-md-3 col-sm-12 mb-sm-3 move-on-hover">
        <router-link
          :to="
            access && userType == 'admin'
              ? { name: 'Dashboard' }
              : { name: 'Sign In' }
          "
        >
          <DefaultInfoCard
            role="button"
            class="indexCard"
            :classIcon="admin.classIcon"
            :title="admin.title"
            :desc="admin.desc"
          />
        </router-link>
      </div>
      <div class="col-md-3 col-sm-12 mb-sm-3 move-on-hover pointer">
        <router-link
          :to="
            access && userType == 'sale'
              ? { name: 'SaleHome' }
              : { name: 'Sign In' }
          "
        >
          <DefaultInfoCard
            role="button"
            class="indexCard"
            :classIcon="sales.classIcon"
            :title="sales.title"
            :desc="sales.desc"
          />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultInfoCard from "@/examples/Cards/DefaultInfoCard.vue";
import { mapState } from "vuex";
export default {
  name: "Index",
  data() {
    return {
      admin: {
        classIcon: "text-white fas fa-user",
        title: "Admin",
        desc: "Manager",
      },
      sales: {
        classIcon: "text-white fas fa-tag",
        title: "Sales",
        desc: "sales member",
      },
    };
  },
  components: {
    DefaultInfoCard,
  },
  computed: {
    ...mapState({
      access: (state) => state.auth.accesstoken,
      userType: (state) => state.auth.user_type,
    }),
  },
};
</script>
<style>
.indexCard .custom {
  background: #fff !important;
  color: #344767 !important;
}
.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 60%);
}
</style>
