<template>
  <div>
    <div class="row mt-4">
      <div class="col-sm-12 col-md-6">
        <div class="col-12 mb-xl-0 mb-2">
          <card
            @click="activate(1, method1)"
            :class="{ m_active: active_el == 1 }"
            role="button"
            :title="method1"
            iconClass="ni ni-world"
            iconBackground="bg-gradient-info"
            directionReverse
          ></card>
        </div>
        <div class="col-12 mb-xl-0 mb-2">
          <card
            @click="activate(2, method2)"
            :class="{ m_active: active_el == 2 }"
            role="button"
            :title="method2"
            iconClass="ni ni-world"
            iconBackground="bg-gradient-info"
            directionReverse
          ></card>
        </div>
        <div class="col-12 mb-xl-0">
          <card
            @click="activate(3, method3)"
            :class="{ m_active: active_el == 3 }"
            role="button"
            :title="method3"
            iconClass="ni ni-world"
            iconBackground="bg-gradient-info"
            directionReverse
          ></card>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="card mb-5">
          <div class="card-header pb-0 p-3">
            <div class="row">
              <div class="col-6 d-flex align-items-center">
                <h6 class="mb-0">{{ selected_method }}</h6>
              </div>
            </div>
          </div>
          <div class="card-body p-3">
            <Cash
              v-if="selected_method == 'Cash'"
              :Discount="Discount"
              :Discount_amount="Discount_amount"
              :Amount="Amount"
            />
            <Cheque
              v-if="selected_method == 'Cheque'"
              :Discount="Discount"
              :Discount_amount="Discount_amount"
              :Amount="Amount"
            />
            <Bank
              v-if="selected_method == 'Bank'"
              :Discount="Discount"
              :Discount_amount="Discount_amount"
              :Amount="Amount"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import VsudButton from "@/components/VsudButton.vue";
import Card from "@/examples/Cards/Card.vue";
import img1 from "../../assets/img/logos/mastercard.png";
import img2 from "../../assets/img/logos/visa.png";
import Cash from "../../examples/payment/cash";
import Cheque from "../../examples/payment/cheque.vue";
import Bank from "../../examples/payment/bank.vue";
export default {
  name: "payment-card",
  props: {
    Discount: Number,
    Discount_amount: Number,
    Amount: Number,
  },
  components: {
    // VsudButton,
    Card,
    Cash,
    Cheque,
    Bank,
  },
  data() {
    return {
      active_el: 1,
      img1,
      img2,
      selected_method: "Cash",
      method1: "Cash",
      method2: "Cheque",
      method3: "Bank",
    };
  },
  methods: {
    activate(el, method) {
      this.active_el = el;
      this.selected_method = method;
    },
  },
};
</script>
<style>
.m_active {
  border: 1px solid #21d4fd !important;
}
</style>
