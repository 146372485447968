<template>
  <div class="container mt-4">
    <div
      class="pt-5 m-3 page-header align-items-start min-vh-50 pb-11 border-radius-lg"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved6.jpg') + ')',
      }"
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="mx-auto text-center col-lg-5">
            <h4 class="mt-5 mb-2 text-white">Select Package!</h4>
            <p class="text-white text-lead">
              Select One Of The Three Packages Below
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-lg-n10 mt-md-n11 mt-n10">
      <div class="row mt-4">
        <div class="mx-auto col-sm-12 col-xl-8 col-lg-8 col-md-8">
          <div class="row mt-4" style="min-height:200px;">
            <div class="card p-3">
              <div class="col-12">
                <div class="row">
                  <div class="col-md-4 col-sm-4" v-for="p in packages" :key="p.id">
                    <package-card
                      @click="
                        activate(p.id, p.name, p.price, p.description),
                          (showDesc = true)
                      "
                      :class="{ p_active: active_el == p.id }"
                      class="mb-sm-3"
                      role="button"
                      :classIcon="full.classIcon"
                      :title="p.name"
                      :desc="full.desc"
                      :price="p.price"
                      :id="p.id"
                    />
                  </div>
                </div>
              </div>
              <p v-if="showDesc" class="text-center my-4">
                {{ description ? description : "loading" }}
              </p>
              <div class="text-center d-flex">
              <vsud-button
                @click="goBack()"
                color="dark"
                fullWidth
                variant="gradient"
                class="my-4 mr-2 mx-2"
                >Back</vsud-button
              >
              <vsud-button
                @click="set_package(id)"
                color="dark"
                fullWidth
                variant="gradient"
                class="my-4 ml-2 mx-2"
                >Next</vsud-button
              >
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VsudButton from "@/components/VsudButton.vue";
import setTooltip from "@/assets/js/tooltip.js";
import PackageCard from "@/examples/Cards/PackageCard.vue";
import instance from "../../store/axiosConfig.js";
export default {
  name: "packages",
  components: {
    PackageCard,
    VsudButton,
  },
  data() {
    return {
      showUp: false,
      showDesc: false,
      description: null,
      active_el: 0,
      packages: null,
      show_payment: false,
      id: null,
      discount: "",
      full: {
        classIcon: "text-white fas fa-landmark",
        title: "Full Package",
        desc: "Full Package",
        price: "+$2000",
      },
      account: {
        classIcon: "text-white fas fa-user",
        title: "Profile Package",
        desc: "Profile Payment",
        price: "$455.00",
      },
      directory: {
        classIcon: "text-white fas fa-ad",
        title: "Directory Package",
        desc: "Directory Payment",
        price: "$455.00",
      },
    };
  },
  computed: {
    getDiscount() {
      let discount = this.price * (this.discount / 100);
      let price = this.price - discount;
      return price;
    },
  },
  methods: {
    goBack(){
      this.$router.go(-1)
    },
    activate(id, name, price, description) {
      this.id = id;
      this.name = name;
      this.price = price;
      this.active_el = id;
      this.description = description;
    },
    get_packages() {
      try {
        this.$store.dispatch("GETPACKAGES").then((res) => {
          this.packages = res.data.packages;
          this.showUp = true;
          console.log(this.packages);
        });
      } catch (err) {
        console.log(err.message);
      }
    },
    set_package(id) {
      try {
        instance
          .put("/company/company_select_package", {
            package_id: id,
          })
          .then((res) => {
            this.$router.push({
              name: "paymentPage",
            });
            console.log(res.data);
          });
      } catch (err) {
        console.log(err.message);
      }
    },
  },
  mounted() {
    setTooltip();
    this.get_packages();
  },
};
</script>
<style>
.p_active {
  border: 2px solid #20253f !important;
}
</style>
