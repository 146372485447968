<template>
  <div>
    <div class="row">
      <div class="col-md-12 mb-md-0 mb-4">
        <label for="">Discount</label>
        <div
          :class="'discount_percentage' in errors ? 'is-invalid' : ''"
          class="card card-body p-0 m-2 border card-plain border-radius-lg d-flex align-items-center flex-row"
        >
          <input
            disabled
            type="text"
            name=""
            placeholder="Discount"
            class="pay-input border-radius-lg"
            :value="Discount"
          />
        </div>
        <p class="mt-2 text-sm text-danger">
          {{ errors.discount_percentage ? errors.discount_percentage[0] : "" }}
        </p>
      </div>
      <div class="col-md-12">
        <label for="">Discount Amount</label>
        <div
          :class="'discount_amount' in errors ? 'is-invalid' : ''"
          class="card card-body p-0 m-2 border card-plain border-radius-lg d-flex align-items-center flex-row"
        >
          <input
            disabled
            type="text"
            name=""
            placeholder="Discount Amount"
            class="pay-input border-radius-lg"
            :value="Discount_amount"
          />
        </div>
        <p class="mt-2 text-sm text-danger">
          {{ errors.discount_amount ? errors.discount_amount[0] : "" }}
        </p>
      </div>
      <div class="col-md-12">
        <label for="">Amount</label>
        <div
          :class="'amount' in errors ? 'is-invalid' : ''"
          class="card card-body p-0 m-2 border card-plain border-radius-lg d-flex align-items-center flex-row"
        >
          <input
            disabled
            type="text"
            name=""
            placeholder="Amount"
            class="pay-input border-radius-lg"
            :value="Amount"
          />
        </div>
        <p class="mt-2 text-sm text-danger">
          {{ errors.amount ? errors.amount[0] : "" }}
        </p>
      </div>
      <div class="col-sm-12">
        <button type="button" class="form-control" @click="Pay()">Pay</button>
      </div>
    </div>
  </div>
</template>
<script>
import instance from "../../store/axiosConfig.js";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "cash",
  data() {
    return {
      errors: {},
    };
  },
  props: {
    Discount: Number,
    Discount_amount: Number,
    Amount: Number,
  },
  methods: {
    Pay() {
      instance
        .post("/company/pay/cash", {
          discount_percentage: this.Discount,
          discount_amount: this.Discount_amount,
          amount: this.Amount,
        })
        .then((res) => {
          console.log(res.data);
          toast.success("Added Successfully!");
          this.$router.push({
            name: "SaleHome",
          });
        })
        .catch((err) => {
          console.log(err.message);
          this.errors = err.response.data.errors;
        });
    },
  },
};
</script>
<style></style>
