<template>
  <div class="card">
    <div class="card-header d-flex justify-content-between pb-0 px-3">
      <h6 class="mb-0">Companies Information</h6>
      <h6 class="mb-0">{{ title }}</h6>
    </div>
    <div class="card-body pt-4 p-3">
      <ul class="list-group">
        <li
          role="button"
          class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg"
          v-for="(company, index) in companies_data"
          :key="index"
        >
          <div class="d-flex flex-column">
            <div class="d-flex px-2 py-1">
              <div>
                <vsud-avatar :img="img1" size="sm" alt="xd" />
              </div>
              <div class="d-flex flex-column justify-content-center">
                <h6 class="mb-0 text-sm">{{ company.name }}</h6>
              </div>
            </div>
            <div style="margin-left: 45px" class="d-flex flex-column">
              <span class="mb-2 text-xs">
                Email Address:
                <span class="text-dark ms-sm-2 font-weight-bold">{{
                  company.email
                }}</span>
              </span>
              <span class="text-xs">
                Phone Number:
                <span class="text-dark ms-sm-2 font-weight-bold">{{
                  company.phone_number
                }}</span>
              </span>
              <span class="text-xs">
                Activity:
                <span class="text-dark ms-sm-2 font-weight-bold">{{
                  company.activity.title
                }}</span>
              </span>
            </div>
          </div>
          <div class="ms-auto text-end">
            <a
              class="btn btn-link text-danger text-gradient px-3 mb-0"
              href="javascript:;"
            >
              Not Complete
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import VsudAvatar from "@/components/VsudAvatar.vue";
import img1 from "../../assets/img/small-logos/logo-xd.svg";
export default {
  name: "company-card",
  data() {
    return {
      img1,
    };
  },
  props: {
    title: String,
    companies_data: String,
  },
  components: {
    VsudAvatar,
  },
  mounted() {
    setTooltip();
  },
};
</script>
