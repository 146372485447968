<template>
  <div>
    <div>
      <div>
        <div class="card">
          <div class="card-header pb-0">
            <div class="actions row align-items-center">
              <div class="d-flex px-2 py-1">
                <div>
                  <vsud-avatar
                    :img="img1"
                    size="sm"
                    border-radius="lg"
                    class="me-3"
                    alt="user1"
                  />
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <h4 class="mb-0 text-lg">{{ $route.params.name }}</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0">
            <SaleCard
              v-if="$route.params.type == 'sale'"
              :title="report ? report.name : 'Loading...'"
              :name="report ? report.name : 'Loading...'"
              :email="report ? report.email : 'Loading...'"
              :phone_number="report ? report.phone_number : 'Loading...'"
              :companies="report ? report.companies : 'Loading...'"
              :income="report ? report.total_income : 'Loading...'"
            />
            <CompanyReport
              v-else
              :title="report ? report.info.business_name : 'Loading...'"
              :name="report ? report.info.business_name : 'Loading...'"
              :email="report ? report.info.email : 'Loading...'"
              :phone_number="report ? report.info.phone_number : 'Loading...'"
              :activity="report ? report.info.activity.title : 'Loading...'"
              :package_name="
                report ? report.info.active_package.name : 'Loading...'
              "
              :active_package="
                report ? report.info.active_package : 'Loading...'
              "
              :expired_package="
                report ? report.info.expired_package : 'Loading...'
              "
              :added_by="report ? report.info.added_by.name : 'Loading...'"
              :created_at="report ? report.info.created_at : 'Loading...'"
              :status="report ? report.status : 'Loading...'"
              :is_not_completed="is_not_completed"
              :is_completed="is_completed"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SaleCard from "../components/SaleCard.vue";
import CompanyReport from "../components/companyReport.vue";
import instance from "../../store/axiosConfig.js";
import VsudAvatar from "@/components/VsudAvatar.vue";
import img1 from "../../assets/img/team-2.jpg";
import { mapState } from "vuex";
export default {
  name: "report",
  data() {
    return {
      report: null,
      img1,
      is_completed: null,
      is_not_completed: null,
    };
  },
  computed: {
    ...mapState({
      userType: (state) => state.auth.user_type,
    }),
  },
  methods: {
    getDetails(type, id) {
      instance
        .post("/report/" + type + "/" + id)
        .then((res) => {
          console.log(res.data);
          this.report = {};
          if (type == "sale") {
            this.report = res.data.data;
          } else {
            this.report = res.data;
          }
          this.report.status.forEach((state) => {
            if (state.status) {
              console.log(state.status);
              this.is_completed = state.status;
            } else {
              console.log(state.status);
              this.is_not_completed = state.status;
            }
          });
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
  },
  mounted() {
    this.getDetails(this.$route.params.type, this.$route.params.id);
  },
  components: {
    VsudAvatar,
    SaleCard,
    CompanyReport,
  },
};
</script>
<style>
.row-hover:hover {
  background: #fafafa;
}
</style>
