<template>
  <div>
    <Report />
  </div>
</template>
<script>
import Report from "./components/report";
export default {
  name: "Reports",
  components: {
    Report,
  },
};
</script>
<style></style>
