<template>
  <div class="card mb-4">
    <div class="card-header d-flex justify-content-between pb-0">
      <h6>Company Table</h6>
      <div class="actions d-flex justify-content-center align-items-center">
        <!-- <vsud-select class="filterSales"></vsud-select> -->
        <vsud-badge
          v-if="userType == 'admin'"
          color="warning"
          role="button"
          variant="gradient"
          size="sm"
          >Report</vsud-badge
        >
        <router-link v-if="userType !== 'admin'" :to="{ name: 'AddCompany' }"
          ><vsud-badge
            style="margin-left: 15px"
            color="info"
            role="button"
            variant="gradient"
            size="sm"
            >Add</vsud-badge
          ></router-link
        >
      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0" v-if="company_table">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Index
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Name
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Phone Number
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Activity
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Package
              </th>
              <th class="text-secondary opacity-7"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="company in company_table"
              :key="company.id"
              role="button"
              class="row-hover"
            >
              <td style="width: 10px; text-align: center">
                <p class="text-xs font-weight-bold mb-0">
                  {{ company.id }}
                </p>
              </td>
              <td
                @click="
                  getDetails('company', company.business_name, company.id)
                "
              >
                <div class="d-flex px-2 py-1">
                  <div>
                    <vsud-avatar
                      :img="company.logo.thumbnail_image"
                      size="sm"
                      border-radius="lg"
                      class="me-3"
                      alt="user1"
                    />
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ company.business_name }}</h6>
                    <p class="text-xs text-secondary mb-0">
                      {{ company.email }}
                    </p>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ company.phone_number }}
                </p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ company.activity.title }}
                </p>
              </td>
              <td>
                <vsud-badge
                  :color="company.active_package ? 'success' : 'danger'"
                  role="button"
                  variant="gradient"
                  size="sm"
                  style="font-size: 10px; line-height: 1.25"
                  ><span class="d-block">
                    {{
                      company.active_package
                        ? company.active_package.status
                        : company.expired_package.status
                    }}</span
                  >
                </vsud-badge>
                <p class="text-xs text-secondary mb-0 mt-1">
                  {{
                    company.active_package
                      ? "Expires on " + company.active_package.end
                      : "Expired at " + company.expired_package.end
                  }}
                </p>
              </td>
              <td class="align-middle" v-if="userType == 'admin'">
                <router-link
                  :to="{
                    name: 'editCompany',
                    query: { company_id: company.id },
                  }"
                  class="text-secondary font-weight-bold text-xs"
                  data-toggle="tooltip"
                  data-original-title="Edit user"
                  >Edit</router-link
                >
                <a
                  style="margin-left: 15px"
                  @click="delete_company(company.id)"
                  class="text-danger font-weight-bold text-xs"
                  data-toggle="tooltip"
                  data-original-title="Delete user"
                  >Delete</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="d-flex justify-content-center align-items-center my-5" v-else>
        <div
          class="orbit-spinner d-flex justify-content-center align-items-center"
        >
          <div class="orbit"></div>
          <div class="orbit"></div>
          <div class="orbit"></div>
        </div>
      </div>
      <div
        class="d-flex justify-content-center align-items-center my-5"
        v-if="report_data"
      >
        <h5 v-if="report_data.length == 0" class="text-dark">No Companies</h5>
      </div>
      <!-- pagination -->
      <div
        class="pagination-container d-flex m-3 justify-content-center"
        v-if="company_table"
      >
        <ul class="pagination pagination-success">
          <li class="page-item">
            <a
              class="page-link"
              @click="CompanyTable(1), (currentPage = 1)"
              aria-label="Previous"
            >
              <span aria-hidden="true"
                ><i class="fa fa-angle-double-left" aria-hidden="true"></i
              ></span>
            </a>
          </li>
          <li class="page-item">
            <a
              class="page-link"
              @click="currentPage > 1 ? CompanyTable(--currentPage) : ''"
              aria-label="Previous"
            >
              <span aria-hidden="true"
                ><i class="fa fa-angle-left" aria-hidden="true"></i
              ></span>
            </a>
          </li>
          <li class="page-item active">
            <a class="page-link">{{ currentPage }}</a>
          </li>
          <li class="page-item">
            <a
              class="page-link"
              aria-label="Next"
              @click="CompanyTable(++currentPage)"
            >
              <span aria-hidden="true"
                ><i class="fa fa-angle-right" aria-hidden="true"></i
              ></span>
            </a>
          </li>
          <li class="page-item">
            <a
              class="page-link"
              @click="CompanyTable(last), (currentPage = last)"
              aria-label="Next"
            >
              <span aria-hidden="true"
                ><i class="fa fa-angle-double-right" aria-hidden="true"></i
              ></span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import VsudAvatar from "@/components/VsudAvatar.vue";
import VsudBadge from "@/components/VsudBadge.vue";
import img1 from "../../assets/img/team-2.jpg";
import { mapState } from "vuex";
import instance from "../../store/axiosConfig.js";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "company table",
  data() {
    return {
      company_table: null,
      currentPage: 1,
      perpage: 1,
      img1,
      total: 0,
      last: null,
    };
  },
  components: {
    VsudAvatar,
    VsudBadge,
  },
  computed: {
    ...mapState({
      userType: (state) => state.auth.user_type,
    }),
  },
  methods: {
    CompanyTable(page) {
      try {
        this.$store.dispatch("GETCOMPANY", page).then((res) => {
          this.total = res.data.meta.total;
          this.last = res.data.meta.last_page;
          this.company_table = res.data.data;
          console.log(this.company_table);
        });
      } catch (err) {
        console.log(err.message);
      }
    },
    delete_company(id) {
      this.$swal({
        title: "Do you want to Delete this?",
        showCancelButton: true,
        confirmButtonText: "Delete",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          instance
            .delete("/company/" + id)
            .then((res) => {
              this.CompanyTable();
              this.$router.push("/companies");
              toast.warning("Deleted!");
              console.log(res.data);
            })
            .catch((err) => {
              console.log(err.message);
            });
        }
      });
    },
    getDetails(type, name, id) {
      this.$router.push({
        path: "/reports/" + type + "/" + name + "/" + id,
        params: { sale_id: id },
      });
    },
  },
  created() {
    this.CompanyTable(this.currentPage);
    console.log(this.company_table);
  },
};
</script>
<style>
.filterSales {
  margin-bottom: 0 !important;
  margin-right: 10px;
}
.filterSales .form-control {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
.row-hover:hover {
  background: #fafafa;
}
</style>
