<template>
  <div class="container-fluid mt-4">
    <div class="row">
      <div class="col-12">
        <div class="row mt-4">
          <div class="col-12">
            <div class="row" v-if="packages">
              <div class="col-md-4" v-for="p in packages" :key="p.id">
                <router-link :to="'/follow-up/' + p.name">
                  <default-info-card
                    @click="activate(p.id)"
                    class="mb-sm-3"
                    :class="{ p_active: active_el == p.id }"
                    role="button"
                    :classIcon="full.classIcon"
                    :title="p.name"
                    :desc="full.desc"
                    :price="p.price"
                  />
                </router-link>
              </div>
            </div>
            <div class="row card" v-else>
              <div
                class="d-flex justify-content-center align-items-center mt-64"
              >
                <div
                  class="orbit-spinner d-flex justify-content-center align-items-center"
                >
                  <div class="orbit"></div>
                  <div class="orbit"></div>
                  <div class="orbit"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import DefaultInfoCard from "@/examples/Cards/DefaultInfoCard.vue";
export default {
  name: "FollowUp",
  components: {
    DefaultInfoCard,
  },
  data() {
    return {
      active_el: 0,
      packages: null,
      full: {
        classIcon: "text-white fas fa-landmark",
        title: "Full Package",
        desc: "Full Package",
        price: "+$2000",
      },
      account: {
        classIcon: "text-white fas fa-user",
        title: "Profile Package",
        desc: "Profile Package",
        price: "$455.00",
      },
      directory: {
        classIcon: "text-white fas fa-ad",
        title: "Directory Package",
        desc: "Directory Package",
        price: "$455.00",
      },
    };
  },
  methods: {
    activate(el) {
      this.active_el = el;
    },
    get_packages() {
      try {
        this.$store.dispatch("GETPACKAGES").then((res) => {
          this.packages = res.data.packages;
          console.log(this.packages);
        });
      } catch (err) {
        console.log(err.message);
      }
    },
  },
  mounted() {
    setTooltip();
    this.get_packages();
  },
};
</script>
<style>
.p_active {
  border: 1px solid #0f0 !important;
}
</style>
