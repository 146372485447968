<template>
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="mx-auto col-sm-12 col-xl-8 col-lg-8 col-md-8">
        <div class="card z-index-0">
          <div class="p-4 text-center card-header">
            <h5>Council Information</h5>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="mb-3 col-md-6 col-sm-12">
                <input
                  class="form-control"
                  v-model="council_name"
                  id="name"
                  name="name"
                  type="text"
                  required=""
                  placeholder="Council Name"
                  aria-label="Name"
                />
              </div>
              <div class="mb-3 col-md-6 col-sm-12">
                <input
                  class="form-control"
                  v-model="email"
                  id="email"
                  name="email"
                  type="text"
                  required=""
                  placeholder="Email"
                  aria-label="Email"
                />
              </div>
            </div>
            <div class="row">
              <div class="mb-3 col-md-6 col-sm-12">
                <input
                  class="form-control"
                  v-model="phone_number"
                  id="phone"
                  name="phone"
                  type="tel"
                  required=""
                  placeholder="Mobile"
                  aria-label="Phone"
                />
              </div>
              <div class="mb-3 col-md-6 col-sm-12">
                <input
                  class="form-control"
                  v-model="landline_number"
                  id="name"
                  name="name"
                  type="tel"
                  required=""
                  placeholder="Landline"
                  aria-label="Name"
                />
              </div>
            </div>
            <div class="row">
              <div class="mb-3 col-md-5 col-sm-12">
                <v-select
                  @option:selected="get_city"
                  class="dropdown"
                  v-model="country"
                  :options="options.countries"
                  :reduce="(country) => country.id"
                  :placeholder="country_status"
                  label="name"
                  taggable
                  push-tags
                >
                </v-select>
              </div>
              <div class="mb-3 col-md-4 col-sm-12">
                <v-select
                  class="dropdown"
                  @option:selected="get_area"
                  v-model="city"
                  :options="options.cities"
                  label="name"
                  :reduce="(city) => city.id"
                  :placeholder="city_status"
                  taggable
                  push-tags
                >
                </v-select>
              </div>
              <div class="mb-3 col-md-3 col-sm-12">
                <v-select
                  class="dropdown"
                  v-model="area"
                  :options="options.areas"
                  label="name"
                  :reduce="(area) => area.id"
                  :placeholder="area_status"
                  taggable
                  push-tags
                >
                </v-select>
              </div>
            </div>
          </div>
          <div class="text-center">
            <h6>Person in Charge</h6>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="mb-3 col-md-6 col-sm-12">
                <input
                  class="form-control"
                  v-model="person_in_charge_name"
                  id="name"
                  name="name"
                  type="text"
                  required=""
                  placeholder="Name"
                  aria-label="Name"
                />
              </div>
              <div class="mb-3 col-md-6 col-sm-12">
                <input
                  class="form-control"
                  v-model="person_in_charge_designation"
                  id="email"
                  name="designation"
                  type="text"
                  required=""
                  placeholder="Designation"
                  aria-label="Designation"
                />
              </div>
            </div>
            <div class="mb-3">
              <input
                class="form-control"
                v-model="person_in_charge_email"
                id="email"
                name="email"
                type="email"
                required=""
                placeholder="Email"
                aria-label="email"
              />
            </div>
            <div class="row">
              <div class="mb-3 col-md-6 col-sm-12">
                <input
                  class="form-control"
                  v-model="person_in_charge_mobile"
                  id="name"
                  name="Mobile"
                  type="tel"
                  required=""
                  placeholder="Mobile"
                  aria-label="Name"
                />
              </div>
            </div>
            <div class="text-center">
              <vsud-button
                @click="EditCouncil($route.query.id)"
                color="dark"
                fullWidth
                variant="gradient"
                class="my-4 mb-2"
                >Save</vsud-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VsudButton from "@/components/VsudButton.vue";
import vSelect from "vue-select";
import instance from "../../store/axiosConfig.js";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "edit-council",
  data() {
    return {
      id: "",
      options: {
        countries: [],
        cities: [],
        areas: [],
      },
      country_status: "Country",
      city_status: "City",
      area_status: "Area",
      council_name: "",
      email: "",
      phone_number: "",
      landline_number: "",
      country: "Country",
      city: "City",
      area: "Area",
      country_id: null,
      city_id: null,
      area_id: null,
      person_in_charge_name: "",
      person_in_charge_designation: "",
      person_in_charge_email: "",
      person_in_charge_mobile: "",
    };
  },
  components: {
    VsudButton,
    vSelect,
  },
  methods: {
    EditCouncil(id) {
      try {
        instance
          .put("/council/" + id, {
            council_name: this.council_name,
            email: this.email,
            mobile_number: this.phone_number,
            landline_number: this.landline_number,
            country_id: this.country_id,
            city_id: this.city_id,
            area_id: this.area_id,
            person_in_charge_name: this.person_in_charge_name,
            person_in_charge_designation: this.person_in_charge_designation,
            person_in_charge_email: this.person_in_charge_email,
            person_in_charge_mobile: this.person_in_charge_mobile,
          })
          .then((res) => {
            console.log(res.data);
            this.$router.push({
              name: "Councils",
            });
            toast.success("Edited Successfully!");
          });
      } catch (err) {
        console.log(err.message);
      }
    },
    GetCouncil(id) {
      try {
        instance.get("/council/" + id).then((res) => {
          console.log(res.data);
          this.council_name = res.data.council_name;
          this.email = res.data.email;
          this.phone_number = res.data.mobile_number;
          this.landline_number = res.data.landline_number;
          this.country_id = res.data.country_id;
          this.city_id = res.data.city_id;
          this.area_id = res.data.area_id;
          this.person_in_charge_name = res.data.person_in_charge_name;
          this.person_in_charge_designation =
            res.data.person_in_charge_designation;
          this.person_in_charge_email = res.data.person_in_charge_email;
          this.person_in_charge_mobile = res.data.person_in_charge_mobile;
        });
      } catch (err) {
        console.log(err.message);
      }
    },
    get_country() {
      try {
        this.$store.dispatch("GETCOUNTRY").then((res) => {
          res.data.countries.forEach((item) => {
            this.options.countries.push(item);
          });
        });
      } catch (err) {
        console.log(err.message);
      }
    },
    get_city() {
      instance
        .get("/data/cities/" + this.country)
        .then((res) => {
          this.options.cities = [];
          console.log(res.data);
          if (res.data.cities.length > 0) {
            res.data.cities.forEach((city) => {
              this.options.cities.push(city);
            });
            this.city = "Select City";
          } else {
            this.city = "Not Found";
          }
        })
        .catch((error) => {
          console.log(error.data);
        });
    },
    get_area() {
      instance
        .get("/data/areas/" + this.city)
        .then((res) => {
          this.options.areas = [];
          console.log(res.data);
          if (res.data.areas.length > 0) {
            res.data.areas.forEach((area) => {
              this.options.areas.push(area);
            });
            this.area = "Select Area";
          } else {
            this.area = "Not Found";
          }
        })
        .catch((error) => {
          console.log(error.data);
        });
    },
  },
  created() {
    this.get_country();
    this.GetCouncil(this.$route.query.id);
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
  },
};
</script>
<style>
.dropdown {
  font-size: 0.875rem;
}
.select::-ms-expand {
  display: none;
  padding: 10px;
}
.dropdown .vs__dropdown-menu {
  height: auto;
  overflow-y: auto;
  float: left;
  position: absolute;
  transform: scale(1, 1);
  transition: transform ease 150ms;
  transform-origin: top;
  background-color: #ffffff;
  border-radius: 6px;
  border: solid 1px #eef0f1;
  box-shadow: 0 20px 40px 0 rgb(0 0 0 / 5%);
  /* padding: 15px 0; */
  /* z-index: 20; */
  margin-top: 10px;
  max-height: 360px;
  width: 100%;
  /* max-width: 332px; */
}
.dropdown .vs__dropdown-menu > * {
  transform: scale(1, 1);
  transition: transform ease 150ms;
  transform-origin: top;
  padding: 15.5px 24px;
  border-bottom: 1px solid #eef0f1;
  font-size: 0.875rem;
  line-height: 24px;
  box-sizing: border-box;
  color: #2b2d2e;
  display: block;
  text-decoration: none;
  text-align: left;
  background: none;
}
.dropdown .vs__dropdown-menu > *:hover {
  font-weight: 600;
  color: #213a63;
}
.dropdown .vs__dropdown-toggle {
  border-radius: 10px;
  border: solid 1px #b6b8b9;
  padding: 5px 10px;
  box-sizing: border-box;
}
.form-control {
  border: solid 1px #888 !important;
}
.form-control:focus {
  border: solid 1px #888 !important;
  box-shadow: none !important;
}
</style>
