<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <company-table />
      </div>
    </div>
  </div>
</template>

<script>
import CompanyTable from "./components/CompanyTable";
export default {
  name: "companies",
  components: {
    CompanyTable,
  },
};
</script>
