<template>
  <div class="card">
    <div class="card-body p-3">
      <div class="d-flex justify-content-between">
        <span class="mb-2 text-xs">
          Name:
          <span class="text-dark ms-sm-2 font-weight-bold">{{ name }}</span>
        </span>
        <span class="mb-2 text-xs">
          Email Address:
          <span class="text-dark ms-sm-2 font-weight-bold">{{ email }}</span>
        </span>
        <span class="text-xs">
          Phone Number:
          <span class="text-dark ms-sm-2 font-weight-bold">{{
            phone_number
          }}</span>
        </span>
        <vsud-badge color="success" role="button" variant="gradient" size="sm"
          >Income: {{ income }}</vsud-badge
        >
      </div>
      <div class="my-5">
        <h6>{{ name + "'s" }} Companies</h6>
      </div>
      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Index
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Name
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Phone Number
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Package
                </th>
                <th class="text-secondary opacity-7"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(company, index) in companies"
                :key="company.id"
                role="button"
                class="row-hover"
              >
                <td style="width: 10px; text-align: center">
                  <p class="text-xs font-weight-bold mb-0">
                    {{ index + 1 }}
                  </p>
                </td>
                <td @click="getDetails('company', company.name, company.id)">
                  <div class="d-flex px-2 py-1">
                    <div>
                      <vsud-avatar
                        :img="img1"
                        size="sm"
                        border-radius="lg"
                        class="me-3"
                        alt="user1"
                      />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ company.name }}</h6>
                      <p class="text-xs text-secondary mb-0">
                        {{ company.email }}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ company.phone_number }}
                  </p>
                </td>
                <td>
                  <vsud-badge
                    :color="company.active_package ? 'success' : 'danger'"
                    role="button"
                    variant="gradient"
                    size="sm"
                    style="font-size: 10px; line-height: 1.25"
                    ><span class="d-block" v-if="company.active_package">
                      {{
                        company.active_package
                          ? company.active_package.status
                          : "loading"
                      }}</span
                    >
                    <span class="d-block" v-else>
                      {{
                        company.expired_package
                          ? company.expired_package.status
                          : "loading"
                      }}</span
                    >
                  </vsud-badge>
                  <p
                    class="text-xs text-secondary mb-0 mt-1"
                    v-if="company.active_package"
                  >
                    {{
                      company.active_package
                        ? "Expires on " + company.active_package.end
                        : "loading"
                    }}
                  </p>
                  <p class="text-xs text-secondary mb-0 mt-1" v-else>
                    {{
                      company.expired_package
                        ? "Expired at " + company.expired_package.end
                        : "loading"
                    }}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
// import VsudAvatar from "@/components/VsudAvatar.vue";
import VsudBadge from "@/components/VsudBadge.vue";
// import img1 from "../../assets/img/small-logos/logo-xd.svg";

export default {
  name: "company-card",
  data() {
    return {};
  },
  props: {
    title: String,
    name: String,
    email: String,
    phone_number: String,
    companies: String,
    income: String,
  },
  components: {
    // VsudAvatar,
    VsudBadge,
  },
  mounted() {
    setTooltip();
  },
};
</script>
