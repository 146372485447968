import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import SoftUIDashboard from "./soft-ui-dashboard";
import vSelect from "vue-select";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import "vue-select/dist/vue-select.css";
import "./assets/css/global.css";

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(Toast);
appInstance.use(VueSweetalert2);
appInstance.use(SoftUIDashboard);
appInstance.use(vSelect);
appInstance.mount("#app");
window.Swal = appInstance.config.globalProperties.$swal;
