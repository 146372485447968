<template>
  <div>
    <div>
      <div>
        <div class="card mb-4">
          <div class="card-header pb-3 pb-0">
            <div class="actions row align-items-center">
              <h4 class="mb-2">Report</h4>
              <div class="row">
                <v-select
                  @option:selected="get_report_data"
                  class="dropdown col-lg col-md-6 col-sm-6 mb-sm-2 mb-xs-2"
                  v-model="report_filter.reportType"
                  :options="[
                    { name: 'Companies', value: 'company' },
                    { name: 'Sales', value: 'sale' },
                  ]"
                  :reduce="(option) => option.value"
                  label="name"
                  taggable
                  push-tags
                >
                </v-select>
                <v-select
                  v-if="report_filter.reportType == 'sale'"
                  @search="get_name($event)"
                  @option:selected="get_report_data"
                  class="dropdown col-lg col-md-6 col-sm-6 mb-sm-2 mb-xs-2"
                  v-model="report_filter.name"
                  :options="report.name"
                  :reduce="(option) => option.id"
                  placeholder="Name"
                  label="name"
                  taggable
                  push-tags
                >
                </v-select>
                <v-select
                  v-else
                  @search="get_name($event)"
                  @option:selected="get_report_data"
                  class="dropdown col-lg col-md-6 col-sm-6 mb-sm-2 mb-xs-2"
                  v-model="report_filter.name"
                  :options="report.name"
                  :reduce="(option) => option.id"
                  placeholder="Name"
                  label="business_name"
                  taggable
                  push-tags
                >
                </v-select>
                <v-select
                  class="dropdown col-lg col-md-6 col-sm-6 mb-sm-2 mb-xs-2"
                  @option:selected="get_report_data"
                  v-model="report_filter.package"
                  :options="report.package"
                  :reduce="(option) => option.id"
                  label="name"
                  placeholder="Package"
                  taggable
                  push-tags
                >
                </v-select>
                <v-select
                  v-if="report_filter.reportType == 'company'"
                  @search="get_activities($event)"
                  @option:selected="get_report_data"
                  class="dropdown col-lg col-md-6 col-sm-6 mb-sm-2 mb-xs-2"
                  v-model="report_filter.activity"
                  :options="report.activity"
                  :reduce="(option) => option.id"
                  placeholder="Activity"
                  label="title"
                  taggable
                  push-tags
                >
                </v-select>
                <v-select
                  @option:selected="get_report_data"
                  class="dropdown col-lg col-md-6 col-sm-6 mb-sm-2 mb-xs-2"
                  v-model="report_filter.date.type"
                  :options="report.date"
                  :reduce="(option) => option.type"
                  placeholder="Date"
                  label="name"
                  taggable
                  push-tags
                >
                </v-select>
                <input
                  type="date"
                  @input="get_report_data"
                  v-model="report_filter.date.from"
                  class="form-control dropdown w-sm-50 mr-sm-1 col-lg col-md-6 col-sm-6 mx-md-3 mb-sm-2 mb-xs-2"
                  placeholder="From"
                  v-if="report_filter.date.type == 'custom'"
                />
                <input
                  type="date"
                  @input="get_report_data"
                  v-model="report_filter.date.to"
                  class="form-control dropdown w-sm-50 ml-sm-1 col-lg col-md-6 col-sm-6 mb-sm-2 mb-xs-2"
                  placeholder="To"
                  v-if="report_filter.date.type == 'custom'"
                />
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div class="table-responsive p-0" v-if="report_data">
              <table
                class="table align-items-center mb-0"
                v-if="report_filter.reportType == 'sale'"
              >
                <thead>
                  <tr>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Index
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Name
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Phone Number
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      Companies Addedd
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      Income
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    @click="
                      getDetails(
                        report_filter.reportType,
                        report.name,
                        report.id
                      )
                    "
                    v-for="(report, index) in report_data"
                    :key="report.id"
                    role="button"
                    class="row-hover"
                  >
                    <td style="width: 10px; text-align: center">
                      <p class="text-xs font-weight-bold mb-0">
                        {{ index + 1 }}
                      </p>
                    </td>
                    <td>
                      <div class="d-flex px-2 py-1">
                        <div>
                          <vsud-avatar
                            :img="report.profile.thumbnail_image"
                            size="sm"
                            border-radius="lg"
                            class="me-3"
                            alt="user1"
                          />
                        </div>
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">{{ report.name }}</h6>
                          <p class="text-xs text-secondary mb-0">
                            {{ report.email }}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0">
                        {{ report.phone_number }}
                      </p>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0">
                        {{ report.companies_added.total }}
                      </p>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0">
                        {{ report.total_income }}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                class="table align-items-center mb-0"
                v-if="report_filter.reportType == 'company'"
              >
                <thead>
                  <tr>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Index
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Name
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Phone Number
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      Activity
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      Package
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      Status
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      Added By
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      created at
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    @click="
                      getDetails(
                        report_filter.reportType,
                        report.business_name,
                        report.id
                      )
                    "
                    role="button"
                    class="row-hover"
                    v-for="(report, index) in report_data"
                    :key="report.id"
                  >
                    <td style="width: 10px; text-align: center">
                      <p class="text-xs font-weight-bold mb-0">
                        {{ index + 1 }}
                      </p>
                    </td>
                    <td>
                      <div class="d-flex px-2 py-1">
                        <div>
                          <vsud-avatar
                            :img="report.logo.thumbnail_image"
                            size="sm"
                            border-radius="lg"
                            class="me-3"
                            alt="user1"
                          />
                        </div>
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">
                            {{ report.business_name }}
                          </h6>
                          <p class="text-xs text-secondary mb-0">
                            {{ report.email }}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0">
                        {{ report.phone_number }}
                      </p>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0">
                        {{
                          report.activity
                            ? report.activity.title
                            : "not selected"
                        }}
                      </p>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0">
                        {{
                          report.active_package
                            ? report.active_package.name
                            : report.expired_package.name
                        }}
                      </p>
                    </td>
                    <td>
                      <vsud-badge
                        :color="report.active_package ? 'success' : 'danger'"
                        role="button"
                        variant="gradient"
                        size="sm"
                        style="font-size: 10px; line-height: 1.25"
                        ><span class="d-block">
                          {{
                            report.active_package
                              ? report.active_package.status
                              : report.expired_package.status
                          }}</span
                        >
                      </vsud-badge>
                      <p class="text-xs text-secondary mb-0 mt-1">
                        {{
                          report.active_package
                            ? "Expires on " + report.active_package.end
                            : "Expired at " + report.expired_package.end
                        }}
                      </p>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0">
                        {{
                          report.added_by
                            ? report.added_by.name
                            : "not selected"
                        }}
                      </p>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold mb-0">
                        {{
                          report
                            ? moment(report.created_at).format("DD-MM-YYYY")
                            : "not selected"
                        }}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="d-flex justify-content-center align-items-center my-5"
              v-else
            >
              <div
                class="orbit-spinner d-flex justify-content-center align-items-center"
              >
                <div class="orbit"></div>
                <div class="orbit"></div>
                <div class="orbit"></div>
              </div>
            </div>
            <div
              class="d-flex justify-content-center align-items-center my-5"
              v-if="report_data"
            >
              <h5 v-if="report_data.length == 0" class="text-dark">
                No Reports
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import instance from "../../store/axiosConfig.js";
import VsudAvatar from "@/components/VsudAvatar.vue";
import VsudBadge from "@/components/VsudBadge.vue";
import img1 from "../../assets/img/team-2.jpg";
import vSelect from "vue-select";
import moment from "moment";
import { mapState } from "vuex";
export default {
  name: "report",
  data() {
    return {
      report_data: null,
      moment,
      report: {
        activity: [],
        reportType: "",
        name: [],
        package: "",
        date: [
          { name: "Current Month", type: "current-month" },
          { name: "Last Month", type: "last-30" },
          { name: "Last Six Month", type: "last-6-month" },
          { name: "Custom", type: "custom" },
          { name: "All", type: "all" },
        ],
      },
      report_filter: {
        activity: null,
        reportType: "sale",
        name: null,
        package: null,
        date: { type: "all" },
      },
      showReport: false,
      img1,
    };
  },
  computed: {
    ...mapState({
      userType: (state) => state.auth.user_type,
    }),
  },
  methods: {
    get_report_data() {
      console.log("test", this.report_filter.date.type == "all");
      if (this.report_filter.reportType == "company") {
        instance
          .post("/report/" + this.report_filter.reportType + "/", {
            company_id: this.report_filter.name || null,
            package_id: this.report_filter.package || null,
            company_activity_id: this.report_filter.activity || null,
            duration:
              this.report_filter.date.type == "all"
                ? null
                : JSON.stringify({
                    type: this.report_filter.date.type || null,
                    from: this.report_filter.date.from || null,
                    to: this.report_filter.date.to || null,
                  }),
          })
          .then((response) => {
            console.log(response.data.data);
            this.report_data = response.data.data;
          })
          .catch((error) => {
            console.log(error.data);
          });
      } else {
        instance
          .post("/report/" + this.report_filter.reportType + "/", {
            sale_id: this.report_filter.name || null,
            package_id: this.report_filter.package || null,
            company_activity_id: this.report_filter.activity || null,
            duration:
              this.report_filter.date.type == "all"
                ? null
                : JSON.stringify({
                    type: this.report_filter.date.type || null,
                    from: this.report_filter.date.from || null,
                    to: this.report_filter.date.to || null,
                  }),
          })
          .then((response) => {
            console.log(response.data.data);
            this.report_data = response.data.data;
          })
          .catch((error) => {
            console.log(error.data);
          });
      }
    },
    get_name(name) {
      if (this.report_filter.reportType == "company") {
        instance
          .get("/data/search_companies/" + name)
          .then((res) => {
            console.log(res.data);
            this.report.name = [];
            res.data.companies.forEach((name) => {
              this.report.name.push(name);
            });
          })
          .catch((err) => {
            console.log(err.message);
          });
      } else {
        instance
          .get("/data/search_sales/" + name)
          .then((res) => {
            console.log(res.data);
            this.report.name = [];
            res.data.sales.forEach((name) => {
              this.report.name.push(name);
            });
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
    },
    get_packages() {
      try {
        this.$store.dispatch("GETPACKAGES").then((res) => {
          this.report.package = res.data.packages;
        });
      } catch (err) {
        console.log(err.message);
      }
    },
    get_activities(act) {
      instance
        .get("/data/company_activities/" + act)
        .then((res) => {
          console.log(res.data);
          this.report.activity = [];
          res.data.activities.forEach((item) => {
            this.report.activity.push(item);
          });
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    getDetails(type, name, id) {
      this.$router.push({
        path: "/reports/" + type + "/" + name + "/" + id,
        params: { sale_id: id },
      });
    },
  },
  mounted() {
    this.get_packages();
    this.get_report_data();
  },
  components: {
    VsudAvatar,
    vSelect,
    VsudBadge,
  },
};
</script>
<style>
.row-hover:hover {
  background: #fafafa;
}
@media only screen and (max-width: 600px) {
  .dropdown {
    margin-bottom: 5px;
  }
}
</style>
