<template>
  <div class="container">
    <div class="card">
      <div class="mx-auto text-center col-lg-5">
        <h3 class="mt-5 mb-2">Checkout!</h3>
      </div>
      <div class="card-body">
        <div class="row mt-5 text-center">
          <h6 class="mb-3">Company Information</h6>
          <div class="row m-3">
            <div class="col">
              <p class="mb-0 text-sm text-capitalize">Name:</p>
              <p class="mb-0 text-sm text-capitalize">Email:</p>
              <p class="mb-0 text-sm text-capitalize">Phone:</p>
            </div>
            <div class="col" style="text-align: left">
              <p class="mb-0 text-sm text-capitalize">
                {{
                  order_info.company_info
                    ? order_info.company_info.business_name
                    : "Loading"
                }}
              </p>
              <p class="mb-0 text-sm text-capitalize">
                {{
                  order_info.company_info
                    ? order_info.company_info.email
                    : "Loading"
                }}
              </p>
              <p class="mb-0 text-sm text-capitalize">
                {{
                  order_info.company_info
                    ? order_info.company_info.phone_number
                    : "Loading"
                }}
              </p>
            </div>
            <div class="col align-items-center">
              <router-link :to="{ name: 'AddCompany' }"
                ><i class="fa fa-solid fa-pen" aria-hidden="true"></i
              ></router-link>
            </div>
          </div>
        </div>
        <div class="row mt-5 text-center">
          <h6 class="mb-5">Selected Package</h6>
          <div class="col text-center">
            <div class="col-md-4 mx-auto">
              <div class="card mb-sm-3 position-relative" role="button">
                <router-link
                  :to="{
                    name: 'editCompany',
                    query: { company_id: order_info.company_info.id || '' },
                  }"
                  class="position-absolute end-0"
                  ><i class="fa fa-solid fa-pen p-3" aria-hidden="true"></i
                ></router-link>
                <div class="p-3 mx-4 text-center card-header">
                  <div
                    class="bg-white icon icon-shape icon-lg bg-gradient-success shadow text-center border-radius-lg custom"
                  >
                    <i
                      class="opacity-10 custom text-white fas fa-landmark"
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
                <div class="p-3 pt-0 text-center card-body">
                  <h5 class="mb-0 text-center">
                    {{
                      order_info.package_info
                        ? order_info.package_info.name
                        : "Loading"
                    }}
                  </h5>
                  <span class="text-xs">
                    {{
                      order_info.package_info
                        ? order_info.package_info.name
                        : "Loading"
                    }}
                    Package</span
                  >
                  <hr class="my-3 horizontal dark" />
                  <h5 class="mb-0">
                    {{
                      order_info.package_info
                        ? order_info.package_info.price
                        : "Loading"
                    }}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-3">
      <div class="mx-auto text-center col-lg-5">
        <h4 class="mt-5 mb-2">Payment Methods!</h4>
      </div>
      <div class="card-body">
        <div class="">
          <div class="col-2 mx-auto text-center pb-5">
            <h6>Discount</h6>
            <input
              class="form-control mt-2"
              placeholder="Discount %"
              v-model="discount"
              type="number"
              max="25"
            />
          </div>
          <div class="row mt-4">
            <div class="mx-auto col-sm-12 col-xl-8 col-lg-8 col-md-8">
              <payment-card
                :Discount="discount"
                :Discount_amount="getDisAmount"
                :Amount="getDiscount"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PaymentCard from "@/views/components/PaymentCard.vue";
import instance from "../../store/axiosConfig";
export default {
  name: "payment-page",
  components: {
    PaymentCard,
  },
  data() {
    return {
      discount: 0,
      order_info: null,
    };
  },
  computed: {
    getDisAmount() {
      let discount = this.order_info.package_info.price * (this.discount / 100);
      return discount;
    },
    getDiscount() {
      let discount = this.order_info.package_info.price * (this.discount / 100);
      let price = this.order_info.package_info.price - discount;
      return price;
    },
  },
  methods: {
    get_order_info() {
      instance
        .get("/company/order_info")
        .then((res) => {
          this.order_info = res.data;
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
  },
  created() {
    this.get_order_info();
  },
};
</script>
