<template>
  <div class="card mt-3">
    <div class="card-body pt-4 p-3">
      <ul class="list-group">
        <li
          role="button"
          class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <div class="mb-2">
              <h6 class="mb-0 text-sm">{{ companyFollowUp.business_name }}</h6>
            </div>
            <div class="d-flex flex-column">
              <span class="mb-2 text-xs">
                Email Address:
                <span class="text-dark ms-sm-2 font-weight-bold">{{
                  companyFollowUp.email
                }}</span>
              </span>
              <span class="mb-2 text-xs">
                phone Number:
                <span class="text-dark ms-sm-2 font-weight-bold">{{
                  companyFollowUp.phone_number
                }}</span>
              </span>
            </div>
          </div>
          <div class="ms-auto text-center">
            <div class="mb-2">
              <h6 class="mb-0 text-sm">Status</h6>
            </div>
            <div>
              <vsud-badge
                v-if="!companyFollowUp.status[0].status"
                color="danger"
                role="button"
                variant="gradient"
                size="sm"
                style="font-size: 10px; line-height: 1.25"
                ><span class="d-block">{{ "Not Completed" }}</span>
              </vsud-badge>
              <vsud-badge
                v-else
                color="success"
                role="button"
                variant="gradient"
                size="sm"
                style="font-size: 10px; line-height: 1.25"
                ><span class="d-block">{{ "Completed" }}</span>
              </vsud-badge>
              <div
                v-for="(state, index) in companyFollowUp.status"
                :key="index"
                class="mt-2 mb-2"
              >
                <p
                  class="text-xs text-secondary mb-0 mt-1"
                  v-if="!state.status"
                >
                  <UploadLogo
                    v-if="state.name == 'Logo'"
                    @src="getlogo($event)"
                  />
                  <UploadBanner
                    v-if="state.name == 'Banner'"
                    @src="getbanner($event)"
                  />
                </p>
              </div>
            </div>
          </div>
          <div class="ms-auto text-center">
            <div class="mb-2">
              <h6 class="mb-0 text-sm">Package</h6>
            </div>
            <div>
              <!--package  -->
              <vsud-badge
                :color="companyFollowUp.active_package ? 'success' : 'danger'"
                role="button"
                variant="gradient"
                size="sm"
                style="font-size: 10px; line-height: 1.25"
                ><span>{{
                  companyFollowUp.active_package
                    ? companyFollowUp.active_package.name + " => "
                    : companyFollowUp.expired_package.name + " => "
                }}</span
                ><span class="" v-if="companyFollowUp.active_package">
                  {{
                    companyFollowUp.active_package
                      ? companyFollowUp.active_package.status
                      : "loading"
                  }}</span
                >
                <span class="" v-else>
                  {{
                    companyFollowUp.expired_package
                      ? companyFollowUp.expired_package.status
                      : "loading"
                  }}</span
                >
              </vsud-badge>
              <p
                class="text-xs text-secondary mb-0 mt-1"
                v-if="companyFollowUp.active_package"
              >
                {{
                  companyFollowUp.active_package
                    ? "Expires on " + companyFollowUp.active_package.end
                    : "loading"
                }}
              </p>
              <p class="text-xs text-secondary mb-0 mt-1" v-else>
                {{
                  companyFollowUp.expired_package
                    ? "Expired at " + companyFollowUp.expired_package.end
                    : "loading"
                }}
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import img1 from "../../assets/img/small-logos/logo-xd.svg";
import VsudBadge from "@/components/VsudBadge.vue";
import UploadLogo from "../../examples/upload/UploadLogo";
import UploadBanner from "../../examples/upload/UploadBanner";
import instance from "../../store/axiosConfig";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "company-card",
  data() {
    return {
      img1,
      logo: null,
      banner: null,
    };
  },
  props: {
    companyFollowUp: String,
  },
  components: {
    VsudBadge,
    UploadLogo,
    UploadBanner,
  },
  methods: {
    getlogo(value) {
      this.logo = value;
      this.Upload_logo(value, this.companyFollowUp.id);
    },
    getbanner(value) {
      this.banner = value;
      console.log(this.banner);
      this.Upload_banner(value);
    },
    Upload_banner(img) {
      let formData = new FormData();
      formData.append("banner", img);
      formData.append("company_id", this.companyFollowUp.id);
      instance
        .post("/banner/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res.data);
          toast.success("Uploaded!");
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    Upload_logo(img, id) {
      let formData = new FormData();
      formData.append("file", img);
      instance
        .post("company/upload_logo/" + id, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res.data);
          toast.success("Uploaded!");
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
  },
  mounted() {
    setTooltip();
  },
};
</script>
