<template>
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class="mt-8 card card-plain">
                <div class="pb-0 card-header text-start">
                  <h3 class="font-weight-bolder text-info text-gradient">
                    Welcome
                  </h3>
                  <p class="mb-0">Enter Email and Password</p>
                </div>

                <div class="card-body">
                  <div>
                    <p class="text-danger">
                      {{ checkError }}
                    </p>
                  </div>
                  <label>Email</label>
                  <input
                    :class="'email' in errors || failed ? 'is-invalid' : ''"
                    type="email"
                    class="form-control"
                    name="email"
                    placeholder="email"
                    Required=""
                    v-model="email"
                  />
                  <label>Password</label>
                  <input
                    :class="'password' in errors || failed ? 'is-invalid' : ''"
                    type="password"
                    class="form-control"
                    name="password"
                    placeholder="password"
                    Required=""
                    v-model="password"
                  />
                  <!-- <vsud-switch id="rememberMe" checked>
                      Remember me
                    </vsud-switch> -->
                  <div class="text-center">
                    <vsud-button
                      class="my-4 mb-2"
                      variant="gradient"
                      color="info"
                      fullWidth
                      @click="login()"
                      >Login
                    </vsud-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div
                class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8"
              >
                <div
                  class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                  :style="{
                    backgroundImage:
                      'url(' +
                      require('@/assets/img/curved-images/curved9.jpg') +
                      ')',
                  }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapState } from "vuex";
// import VsudSwitch from "@/components/VsudSwitch.vue";
import VsudButton from "@/components/VsudButton.vue";
const body = document.getElementsByTagName("body")[0];
export default {
  name: "signin",
  data() {
    return {
      email: "",
      password: "",
      errorMessage: "",
      errors: {},
      failed: false,
    };
  },
  components: {
    VsudButton,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    checkError() {
      return this.errorMessage;
    },
    ...mapState({
      access: (state) => state.auth.accesstoken,
      name: (state) => state.auth.name,
    }),
  },
  methods: {
    login() {
      this.$store
        .dispatch("LOGIN", {
          email: this.email,
          password: this.password,
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.user_type == "admin") {
            this.$router.push({ name: "Dashboard" });
          } else {
            this.$router.push({ name: "SaleHome" });
          }
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.errors = err.response.data.errors;
          } else {
            this.failed = true;
          }
          if (this.email || this.password) {
            this.errorMessage = err.response.data.message;
          } else {
            this.errorMessage = "Email and Password is Requird";
          }
          console.log(err.response.data.errors);
        });
    },
  },
  beforeMount() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
};
</script>
