import { createRouter, createWebHistory } from "vue-router";
import Index from "../views/index.vue";
import Dashboard from "@/views/Dashboard.vue";
import Sales from "@/views/Sales.vue";
import Companies from "@/views/Companies.vue";
import FollowUp from "@/views/FollowUp.vue";
import Reports from "@/views/Reports.vue";
import ReportDetails from "@/views/components/reportDetails.vue";
import SignIn from "@/views/SignIn.vue";
import SaleHome from "@/views/SaleHome.vue";
import AddSales from "@/views/components/AddSales.vue";
import AddCompany from "@/views/components/AddCompany.vue";
import banner from "@/views/components/bannerTable.vue";
import editCompany from "@/views/components/editCompany.vue";
import EditSale from "@/views/components/editSale.vue";
import Packages from "@/views/components/Packages.vue";
import paymentPage from "@/views/components/paymentPage.vue";
import AddAdmin from "@/views/components/AddAdmin.vue";
import PackageFull from "@/views/components/PackageFull.vue";
import PackageProfile from "@/views/components/PackageProfile.vue";
import PackageDirectory from "@/views/components/PackageDirectory.vue";
import Councils from "@/views/Councils.vue";
import RegisterCouncil from "@/views/components/RegisterCouncil.vue";
import editCouncil from "@/views/components/editCouncil.vue";
import EventsTable from "@/views/components/EventsTable.vue";
import MediaTable from "@/views/components/MediaTable.vue";
import addEvent from "@/views/components/addEvent.vue";
import addMedia from "@/views/components/addMedia.vue";

import { useToast } from "vue-toastification";
const toast = useToast();
// import store from "../store/index";
const routes = [
  {
    path: "/",
    name: "index",
    component: Index,
    meta: {
      hide: true,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/sale-home",
    name: "SaleHome",
    component: SaleHome,
    meta: {
      hide: true,
    },
  },
  {
    path: "/sales",
    name: "Sales",
    component: Sales,
  },
  {
    path: "/edit-sale/:id",
    name: "EditSale",
    component: EditSale,
  },
  {
    path: "/companies",
    name: "Companies",
    component: Companies,
  },
  {
    path: "/banner-table",
    name: "banner",
    component: banner,
  },
  {
    path: "/reports",
    name: "Reports",
    component: Reports,
  },
  {
    path: "/reports/:type/:name/:id",
    name: "ReportDetails",
    component: ReportDetails,
  },
  {
    path: "/select-package",
    name: "Packages",
    component: Packages,
  },
  {
    path: "/checkout",
    name: "paymentPage",
    component: paymentPage,
  },
  {
    path: "/follow-up",
    name: "followUp",
    component: FollowUp,
    children: [
      {
        path: "full",
        component: PackageFull,
      },
      {
        path: "profile",
        component: PackageProfile,
      },
      {
        path: "directory",
        component: PackageDirectory,
      },
    ],
  },
  {
    path: "/sign-in",
    name: "Sign In",
    component: SignIn,
    meta: {
      hide: true,
    },
  },
  {
    path: "/add-admin",
    name: "AddAdmin",
    component: AddAdmin,
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("user_type"));
      if (localStorage.getItem("user_type") == "admin") {
        next();
      } else {
        toast.error("You Have To Log In As Admin!");
        next({ name: "index" });
      }
    },
  },
  {
    path: "/add-sales-member",
    name: "AddSalesMember",
    component: AddSales,
  },
  {
    path: "/add-company",
    name: "AddCompany",
    component: AddCompany,
  },
  {
    path: "/edit-company/",
    name: "editCompany",
    component: editCompany,
  },
  {
    path: "/councils",
    name: "Councils",
    component: Councils,
  },
  {
    path: "/register-council",
    name: "RegisterCouncil",
    component: RegisterCouncil,
  },
  {
    path: "/edit-council/",
    name: "editCouncil",
    component: editCouncil,
  },
  {
    path: "/media",
    name: "Media",
    component: MediaTable,
  },
  {
    path: "/events",
    name: "Events",
    component: EventsTable,
  },
  {
    path: "/add-media",
    name: "addMedia",
    component: addMedia,
  },
  {
    path: "/add-event",
    name: "addEvent",
    component: addEvent,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});
router.beforeEach((to, from, next) => {
  if (!localStorage.getItem("access_token")) {
    if (to.name == "Sign In" || to.name == "index") {
      console.log(localStorage.getItem("access_token"));
      next();
    } else {
      toast.error("You Have To Log In First!");
      next({ name: "index" });
    }
  } else {
    next();
  }
});
export default router;
