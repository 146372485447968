import instance from "../axiosConfig";
export default {
  state: {
    accesstoken: JSON.parse(localStorage.getItem("access_token")) || null,
    loggedIn: false,
    company_data: "",
    company_table: "",
    name: localStorage.getItem("name") || null,
    activities: "",
    legal_status: "",
    issued_by: "",
    country: "",
    city: "",
    area: "",
    city_id: "",
    area_id: "",
  },

  getters: {
    auth(state) {
      return state.loggedIn;
    },
    company(state) {
      return state.company_table;
    },
  },

  mutations: {
    ADD_COMPANY(state, data) {
      state.company_data = data;
    },
    GET_COMPANY(state, data) {
      state.company_table = data;
    },
    GET_ACTIVITIES(state, data) {
      state.activities = data;
    },
    GET_LEGALSTATUS(state, data) {
      state.legal_status = data;
    },
    GET_ISSUEDBY(state, data) {
      state.issued_by = data;
    },
    GET_COUNTRY(state, data) {
      state.country = data;
    },
    GET_CITY(state, data) {
      state.city = data;
    },
    GET_AREA(state, data) {
      state.area = data;
    },
    GET_CITY_ID(state, data) {
      state.city_id = data;
    },
    GET_AREA_ID(state, data) {
      state.area_id = data;
    },
  },
  actions: {
    ADDCOMPANY({ commit }, { ...data }) {
      console.log(data);
      return new Promise((resolve, reject) => {
        instance
          .post("/company", data)
          .then((response) => {
            commit("ADD_COMPANY", data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    GETCOMPANY({ commit }, page) {
      return new Promise((resolve, reject) => {
        instance
          .get("/company?page=" + page)
          .then((response) => {
            commit("GET_COMPANY", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    GETACTIVITIES({ commit }) {
      return new Promise((resolve, reject) => {
        instance
          .get("/data/company_activities/")
          .then((response) => {
            commit("GET_ACTIVITIES", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    GETLEGALSTATUS({ commit }) {
      return new Promise((resolve, reject) => {
        instance
          .get("/data/legal_statuses")
          .then((response) => {
            commit("GET_LEGALSTATUS", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    GETISSUEDBY({ commit }) {
      return new Promise((resolve, reject) => {
        instance
          .get("/data/issued_by")
          .then((response) => {
            commit("GET_ISSUEDBY", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    GETCOUNTRY({ commit }) {
      return new Promise((resolve, reject) => {
        instance
          .get("/data/countries")
          .then((response) => {
            commit("GET_COUNTRY", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    GETCITY({ commit }) {
      return new Promise((resolve, reject) => {
        instance
          .get("/data/cities/")
          .then((response) => {
            console.log(response.data);
            commit("GET_CITY", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    GETAREA({ commit }) {
      return new Promise((resolve, reject) => {
        instance
          .get("/data/areas/id")
          .then((response) => {
            console.log(response.data);
            commit("GET_AREA", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
