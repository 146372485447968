<template>
  <div class="card">
    <div class="card-header d-flex justify-content-between pb-0 px-3">
      <h6 class="mb-0">Companies Information</h6>
      <h6 class="mb-0">{{ created_at }}</h6>
    </div>
    <div class="card-body pt-4 p-3">
      <ul class="list-group">
        <li
          role="button"
          class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <div class="mb-2">
              <h6 class="mb-0 text-sm">{{ name }}</h6>
            </div>
            <div class="d-flex flex-column">
              <span class="mb-2 text-xs">
                Added By:
                <span class="text-dark font-weight-bold ms-sm-2">{{
                  added_by
                }}</span>
              </span>
              <span class="mb-2 text-xs">
                Email Address:
                <span class="text-dark ms-sm-2 font-weight-bold">{{
                  email
                }}</span>
              </span>
              <span class="mb-2 text-xs">
                phone Number:
                <span class="text-dark ms-sm-2 font-weight-bold">{{
                  phone_number
                }}</span>
              </span>
              <span class="text-xs">
                Activities:
                <span class="text-dark ms-sm-2 font-weight-bold">{{
                  activity
                }}</span>
              </span>
            </div>
          </div>
          <div class="ms-auto text-center">
            <div class="mb-2">
              <h6 class="mb-0 text-sm">Status</h6>
            </div>
            <div>
              <vsud-badge
                v-if="!is_not_completed"
                color="danger"
                role="button"
                variant="gradient"
                size="sm"
                style="font-size: 10px; line-height: 1.25"
                ><span class="d-block">{{ "Not Completed" }}</span>
              </vsud-badge>
              <div
                v-for="(state, index) in status"
                :key="index"
                class="mt-2 mb-2"
              >
                <p
                  class="text-xs text-secondary mb-0 mt-1"
                  v-if="!state.status"
                >
                  {{ state ? state.name : "loading" }}
                </p>
              </div>
              <vsud-badge
                v-if="is_completed"
                color="success"
                role="button"
                variant="gradient"
                size="sm"
                style="font-size: 10px; line-height: 1.25"
                ><span class="d-block">{{ "Completed" }}</span>
              </vsud-badge>
            </div>
            <div v-for="state in status" :key="state" class="mt-2">
              <p class="text-xs text-secondary mb-0 mt-1" v-if="state.status">
                {{ state ? state.name : "loading" }}
              </p>
            </div>
          </div>
          <div class="ms-auto text-center">
            <div class="mb-2">
              <h6 class="mb-0 text-sm">Package</h6>
            </div>
            <div>
              <!--package  -->
              <vsud-badge
                :color="active_package ? 'success' : 'danger'"
                role="button"
                variant="gradient"
                size="sm"
                style="font-size: 10px; line-height: 1.25"
                ><span>{{ package_name + " => " }}</span
                ><span class="" v-if="active_package">
                  {{ active_package ? active_package.status : "loading" }}</span
                >
                <span class="" v-else>
                  {{
                    expired_package ? expired_package.status : "loading"
                  }}</span
                >
              </vsud-badge>
              <p class="text-xs text-secondary mb-0 mt-1" v-if="active_package">
                {{
                  active_package
                    ? "Expires on " + active_package.end
                    : "loading"
                }}
              </p>
              <p class="text-xs text-secondary mb-0 mt-1" v-else>
                {{
                  expired_package
                    ? "Expired at " + expired_package.end
                    : "loading"
                }}
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import img1 from "../../assets/img/small-logos/logo-xd.svg";
import VsudBadge from "@/components/VsudBadge.vue";
export default {
  name: "company-card",
  data() {
    return {
      img1,
    };
  },
  props: {
    title: String,
    name: String,
    email: String,
    phone_number: String,
    activity: String,
    package_name: String,
    active_package: String,
    expired_package: String,
    created_at: String,
    added_by: String,
    status: String,
    is_completed: String,
    is_not_completed: String,
  },
  components: {
    VsudBadge,
  },
  mounted() {
    setTooltip();
  },
};
</script>
