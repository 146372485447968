<template>
  <!-- <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow"
          btnBackground="bg-gradient-success"
          v-bind:darkMode="true"
        />
      </div>
    </div>
  </div> -->
  <main class="mt-0 main-content main-content-bg">
    <section>
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
              <div class="mt-8 card card-plain">
                <div class="pb-0 card-header text-start">
                  <h3 class="font-weight-bolder text-info text-gradient">
                    Add Sales Member
                  </h3>
                </div>
                <div class="card-body">
                  <label>Name</label>
                  <input
                    v-model="name"
                    :class="'name' in errors ? 'is-invalid' : ''"
                    class="form-control"
                    type="text"
                    placeholder="Name"
                    name="name"
                  />
                  <p class="mt-2 text-sm invalid-feedback">
                    {{ errors.name ? errors.name[0] : "" }}
                  </p>
                  <label>Email</label>
                  <input
                    :class="'email' in errors ? 'is-invalid' : ''"
                    v-model="email"
                    class="form-control"
                    type="email"
                    placeholder="Email"
                    name="email"
                  />
                  <p class="mt-2 text-sm invalid-feedback">
                    {{ errors.email ? errors.email[0] : "" }}
                  </p>
                  <label>Phone Number</label>
                  <input
                    :class="'phone_number' in errors ? 'is-invalid' : ''"
                    v-model="phone_number"
                    class="form-control"
                    type="text"
                    placeholder="Phone Number"
                    name="text"
                  />
                  <p class="mt-2 text-sm invalid-feedback">
                    {{ errors.phone_number ? errors.phone_number[0] : "" }}
                  </p>
                  <label>Gender</label>
                  <select
                    v-model="gender"
                    class="form-control"
                    placeholder="Gender"
                    name="gender"
                    :class="'gender' in errors ? 'is-invalid' : ''"
                  >
                    <option value="Gender">Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                  <p class="mt-2 text-sm invalid-feedback">
                    {{ errors.gender ? errors.gender[0] : "" }}
                  </p>
                  <label>Birth Date</label>
                  <input
                    :class="'dob' in errors ? 'is-invalid' : ''"
                    v-model="birth_date"
                    class="form-control"
                    type="date"
                    placeholder="Birth Date"
                    name="date"
                  />
                  <p class="mt-2 text-sm invalid-feedback">
                    {{ errors.dob ? errors.dob[0] : "" }}
                  </p>
                  <div class="text-center">
                    <vsud-button
                      @click="add()"
                      class="my-4 mb-2"
                      variant="gradient"
                      color="info"
                      fullWidth
                      >ِAdd
                    </vsud-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div
                class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8"
              >
                <div
                  class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                  :style="{
                    backgroundImage:
                      'url(' +
                      require('@/assets/img/curved-images/curved9.jpg') +
                      ')',
                  }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
// import Navbar from "@/examples/PageLayout/Navbar.vue";
// import VsudSwitch from "@/components/VsudSwitch.vue";
import VsudButton from "@/components/VsudButton.vue";
import { useToast } from "vue-toastification";
const toast = useToast();
const body = document.getElementsByTagName("body")[0];

export default {
  name: "add-sales-member",
  data() {
    return {
      errors: {},
      name: "",
      email: "",
      phone_number: "",
      sales_area: "",
      gender: "",
      birth_date: "",
    };
  },
  components: {
    // Navbar,
    VsudButton,
  },
  methods: {
    add() {
      this.$store
        .dispatch("ADDSALES", {
          name: this.name,
          email: this.email,
          phone_number: this.phone_number,
          sales_area: this.sales_area,
          gender: this.gender,
          dob: this.birth_date,
        })
        .then((res) => {
          console.log(res.data);
          toast.success("Added Successfully!");
          this.$router.push({ name: "Dashboard" });
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          console.log(err.message);
        });
    },
  },
  beforeMount() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
};
</script>
