<template>
  <div class="container center">
    <div class="row justify-content-center align-items-center mx-auto">
      <div class="col-md-3 col-sm-12 mb-sm-3 move-on-hover">
        <router-link :to="{ name: 'AddCompany' }">
          <DefaultInfoCard
            role="button"
            class="indexCard"
            :classIcon="add_company.classIcon"
            :title="add_company.title"
            :desc="add_company.desc"
          />
        </router-link>
      </div>
      <div class="col-md-3 col-sm-12 mb-sm-3 move-on-hover pointer">
        <router-link :to="{ name: 'Reports' }">
          <DefaultInfoCard
            role="button"
            class="indexCard"
            :classIcon="companies.classIcon"
            :title="companies.title"
            :desc="companies.desc"
          />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultInfoCard from "@/examples/Cards/DefaultInfoCard.vue";
import { mapState } from "vuex";
export default {
  name: "sale-home",
  data() {
    return {
      add_company: {
        classIcon: "text-white fas fa-user",
        title: "Add Company",
        desc: "add new company",
      },
      companies: {
        classIcon: "text-white fas fa-tag",
        title: "My Companies",
        desc: "report for my companies",
      },
      followup: {
        classIcon: "text-white fas fa-tag",
        title: "Follow Up",
        desc: "follow up the last status",
      },
    };
  },
  components: {
    DefaultInfoCard,
  },
  computed: {
    ...mapState({
      access: (state) => state.auth.accesstoken,
      userType: (state) => state.auth.user_type,
    }),
  },
};
</script>
<style>
.indexCard .custom {
  background: #fff !important;
  color: #344767 !important;
}
.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 60%);
}
</style>
